import "../Privacy.css";


export const descriptions = {
    "Privacy Policies": `
        <div>
            <p class="mb-3">Lattice respects your preferences concerning the collection and use of your Personal Information. The following Lattice privacy policies are tailored for the different ways your Personal Information may be collected, used, shared and processed by different Lattice lines of business:</p>
            <ul>
                <li class="mb-2">The Lattice General Privacy Policy addresses information we collect in connection with your use of Lattice websites, mobile applications, and social media pages that link to the General Privacy Policy, your interactions with Lattice during in-person meetings at Lattice facilities or at Lattice events, and in the context of other online or offline sales and marketing activities.</li>

                <li class="mb-2">The Services Privacy Policy describes our privacy and security practices that apply when handling (i) services personal information in order to perform Consulting, Technical Support, Cloud and other services on behalf of Lattice customers; and (ii) personal information contained in systems operation data generated by the interaction of (end-)users of these services with Lattice systems and networks.</li>

                <li class="mb-2">The Customer Data Research and Development Privacy Policy describes Lattice’s processing of personal information in connection with Lattice’s artificial intelligence and machine learning activities in order to analyze, develop, and improve Lattice products and services, and for security and compliance purposes.</li>

                 <li class="mb-2">The Lattice Recruiting Privacy Policy addresses information we may collect in connection with Lattice’s online and offline recruiting efforts.</li>

                  <li class="mb-2">The Lattice Advertising Privacy Policy addresses how Lattice facilitates the collection and use of marketing and interest-based information to help enable interest-based advertising by our Lattice Advertising customers.</li>

                   <li class="mb-2">The Dyn Internet Performance Tools Privacy Policy provides information on the collection, use, sharing and processing of personal information by Lattice in connection with Dyn’s Recursive DNS service, Updater Client, Gauge web browser extension, and RUM beacons.</li>

                     <li class="mb-2">The Lattice Life Sciences Healthcare Research Privacy Policy addresses the processing of personal information by Lattice Life Sciences to facilitate Lattice health research activities.</li>

            </ul>

            <p>Pursuant to the E.U. General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable laws and regulations, individuals may have data subject rights enabling them to request to access, delete, correct, remove or limit the use, or receive a copy of their personal information in Lattice’s possession or for which Lattice is otherwise responsible. If you would like to exercise your rights, please refer to the applicable privacy policy above or visit the Privacy Choices tab for more information.</p>
        </div>
    `,



    "Terms of Use": `
        <div>
            <p class="mb-5">Welcome to the Lattice Web sites (the “Site”). Through the Site, you have access to a variety of resources and content. These include: (a) software and software as a service offerings (“Software”); (b) Web pages, data, messages, text, images, photographs, graphics, audio and video such as podcasts and Webcasts, and documents such as press releases, white papers and product data sheets (“Materials”); and (c) forums, discussion groups, chat areas, bulletin boards, blogs, wikis, e-mail functions, and other services in connection with which you can upload, download, share, email, post, publish, transmit or otherwise access or make available Content (as defined below) (“Community Services”). Software, Materials, Community Services, and other information, content and services are collectively referred to as “Content.” The following are terms of a legal agreement between you (“You” or “Your”) and Lattice Corporation and its affiliated companies (“We” or “Lattice”). By accessing or using the Site or the Content provided on or through the Site, you agree to follow and be bound by the following terms and conditions concerning your access to and use of the Site and the Content provided on or through the Site (“Terms of Use”) and our Privacy Policy. Lattice may revise the Terms of Use and Privacy Policy at any time without notice to you. The revised Terms of Use and Privacy Policy will be effective when posted. 

            </p>

            <h1 class="heading-of-dyanamic-contet">1. Terms Applicable to Specific Content and Areas of the Site</h1>

            <p class="mb-3 mt-4">Some areas of the Site or Content provided on or through the Site may have additional rules, guidelines, license agreements, user agreements or other terms and conditions that apply to your access or use of that area of the Site or Content (including terms and conditions applicable to a corporation or other organization and its users). If there is a conflict or inconsistency between these Terms of Use and the rules, guidelines, license agreement, user agreement or other terms and conditions for a specific area of the Site or for specific Content, the latter shall have precedence with respect to your access and use of that area of the Site or Content.</p>

             <h1 class="heading-of-dyanamic-contet">2. Use of Software</h1>

             <p class="mb-3 mt-4">Your use of Software is subject to all agreements such as a license agreement or user agreement that accompanies or is included with the Software, ordering documents, exhibits, and other terms and conditions that apply (“License Terms”). In the event that Software is provided on or through the Site and is not licensed for your use through License Terms specific to the Software, you may use the Software subject to the following: (a) the Software may be used solely for your personal, informational, noncommercial purposes; (b) the Software may not be modified or altered in any way; and (c) the Software may not be redistributed.</p>

              <h1 class="heading-of-dyanamic-contet">3. Use of Materials</h1>

             <p class="mb-3 mt-4">You may download, store, display on your computer, view, listen to, play and print Materials that Lattice publishes or broadcasts on the Site or makes available for download through the Site subject to the following: (a) the Materials may be used solely for your personal, informational, noncommercial purposes; (b) the Materials may not be modified or altered in any way; and (c) the Materials may not be redistributed.</p>

             <h1 class="heading-of-dyanamic-contet">4. Use of Community Services</h1>
            <p class="mb-3 mt-4">
                Community Services are provided as a convenience to users and Lattice is not obligated to provide any technical support for, or participate in, Community Services. While Community Services may include information regarding Lattice products and services, including information from Lattice employees, they are not an official customer support channel for Lattice.
            </p>
            <p class="mb-3 mt-4">
                You may use Community Services subject to the following: (a) Community Services may be used solely for your personal, informational, noncommercial purposes; (b) Content provided on or through Community Services may not be redistributed or used in violation, or with the purpose to violate, these Terms of Use; and (c) personal data about other users may not be stored or collected except where expressly authorized by Lattice.
            </p>

            <h1 class="heading-of-dyanamic-contet">5. Reservation of Rights</h1>
            <p class="mb-3 mt-4">
                The Site and Content provided on or through the Site are the intellectual property and copyrighted works of Lattice or a third party provider. All rights, title and interest not expressly granted with respect to the Site and Content provided on or through the Site are reserved. All Content is provided on an “As Is” and “As Available” basis, and Lattice reserves the right to terminate the permissions granted to you in Sections 2, 3 and 4 above and your use of the Content at any time.
            </p>

            <h1 class="heading-of-dyanamic-contet">6. Your Content</h1>
            <p class="mb-3 mt-4">
                You agree that you will only upload, share, post, publish, transmit, or otherwise make available (“Share”) on or through the Site Content that you have the right and authority to Share and for which you have the right and authority to grant to Lattice all of the licenses and rights set forth herein. By Sharing Content, you grant Lattice a worldwide, perpetual, royalty-free, irrevocable, nonexclusive, fully sublicensable license to use, reproduce, modify, adapt, translate, host, publish, publicly perform, publicly display, broadcast, transmit, stream and distribute the Content for any purpose and in any form, medium, or technology now known or later developed. This includes, without limitation, the right to incorporate or implement the Content into any Lattice product or service, and to display, market, sublicense and distribute the Content as incorporated or embedded in any product or service distributed or offered by Lattice without compensation to you. You warrant that: (a) you have the right and authority to grant this license; (b) Lattice’s exercise of the rights granted pursuant to this license will not infringe or otherwise violate any third party rights; and (c) all so-called moral rights in the Content have been waived to the full extent allowed by law.
            </p>
            <p class="mb-3 mt-4">
                You agree that you will neither use the Site in a manner, nor Share any Content, that: (a) is false or misleading; (b) is defamatory, derogatory, degrading or harassing of another or constitutes a personal attack; (c) invades another’s privacy or includes, copies or transmits another’s confidential, sensitive or personal information; (d) promotes bigotry, racism, hatred or harm against any group or individual; (e) is obscene or not in good taste; (f) violates or infringes or promotes the violation or infringement of another’s rights, including intellectual property rights; (g) you do not have the right and authority to Share and grant the necessary rights and licenses for; (h) violates or promotes the violation of any applicable laws or regulations; (i) contains a solicitation of funds, goods or services, or promotes or advertises goods or services; or (j) contains any viruses, Trojan horses, or other components designed to limit or harm the functionality of a computer. Lattice may report you to the relevant authorities and may act under the fullest extent of applicable laws if you transmit or upload content intended or designed to cause harm and we will report any Content you Share that violates any applicable laws where we are required to do so by law.
            </p>
            <p class="mb-3 mt-4">
                Lattice does not want to receive confidential information from you through or in connection with the Site. Notwithstanding anything that you may note or state in connection with Sharing Content, it shall not be considered confidential information and shall be received and treated by Lattice on a non-confidential and unrestricted basis and Lattice shall not take and shall not be required to take any steps to safeguard the confidentiality of any information that you Share, other than as specified in Lattice’s Privacy Policy.
            </p>

            <h1 class="heading-of-dyanamic-contet">7. Security, Passwords and Means of Accessing the Site and Content</h1>
            <p class="mb-3 mt-4">
                You agree not to access or use the Site in any manner that could damage, disable, overburden, or impair any Lattice accounts, computer systems or networks. You agree not to attempt to gain unauthorized access to any parts of the Site or any Lattice accounts, computer systems or networks. You agree not to interfere or attempt to interfere with the proper working of the Site or any Lattice accounts, computer systems or networks. You agree not to use any robot, spider, scraper or other automated means to access the Site or any Lattice accounts, computer systems or networks without Lattice’s express written permission.
            </p>
            <p class="mb-3 mt-4">
                If any of the Services require you to open an account, you must complete the registration process by providing us with current, complete and accurate information as required by the applicable registration form. You may also be required to choose a password and a user name. Access to and use of password-protected or secure areas of the Site are restricted to authorized users only. You agree not to share your password(s), account information, or access to the Site with any other person. You are responsible for maintaining the confidentiality of password(s) and account information, and you are responsible for all activities that occur through the use of your password(s) or account(s) or as a result of your access to the Site. You agree to notify Lattice immediately of any use of your password(s) or account(s) that you did not authorize or that is not authorized by these Terms of Use.
            </p>

            <h1 class="heading-of-dyanamic-contet">8. No Unlawful or Prohibited Use</h1>
            <p class="mb-3 mt-4">
                You agree not to use the Site, or use or Share Content provided on or through the Site, for any purpose that is unlawful or prohibited by these Terms of Use or the rules, guidelines or terms of use posted for a specific area of the Site or Content provided on or through the Site.
            </p>

            <h1 class="heading-of-dyanamic-contet">9. Indemnity</h1>
            <p class="mb-3 mt-4">
                You agree to indemnify and hold harmless Lattice, its officers, directors, employees and agents from and against any and all claims, liabilities, damages, losses or expenses, including reasonable attorneys’ fees and costs, due to or arising out of Content that you Share, your violation of these Terms of Use or any additional rules, guidelines or terms of use posted for a specific area of the Site or Content provided on or through the Site, or your violation or infringement of any third party rights, including intellectual property rights.
            </p>

            <h1 class="heading-of-dyanamic-contet">10. Monitoring</h1>
            <p class="mb-3 mt-4">
                Lattice has no obligation to monitor the Site or screen Content that is Shared on or through the Site. However, Lattice reserves the right to review the Site and Content and to monitor all use of and activity on the Site, and to remove or choose not to make available on or through the Site any Content in its sole discretion. Lattice may remove Content that is confidential or proprietary to a third party without that third party’s permission. We may employ technology and/or human moderators to review Content in Community Services proactively and reactively (for example, upon receipt of notice from users or authorities). Lattice’s approach to content moderation is outlined here.
            </p>

            <h1 class="heading-of-dyanamic-contet">11. Termination of Use</h1>
            <p class="mb-3 mt-4">
                Lattice may, in its sole discretion, at any time discontinue providing or limit access to the Site, any areas of the Site or Content provided on or through the Site. You agree that Lattice may, in its sole discretion, at any time, terminate or limit your access to, or use of, the Site or any Content. Lattice may terminate or limit your access to or use of the Site if Lattice determines, in its sole discretion, that you have infringed the copyrights of a third party or you have violated any applicable laws. You agree that Lattice shall not be liable to you or any third-party for any termination or limitation of your access to, or use of, the Site or any Content, including Content that you may have Shared.
            </p>

            <h1 class="heading-of-dyanamic-contet">12. Third Party Web Sites, Content, Products and Services</h1>
            <p class="mb-3 mt-4">
                The Site provides links to Web sites and access to Content, products and services of third parties, including users, advertisers, affiliates and sponsors of the Site. Lattice is not responsible for third party Content provided on or through the Site or for any changes or updates to such third-party sites, and you bear all risks associated with the access to, and use of, such Web sites and third party Content, products and services.
            </p>

            <h1 class="heading-of-dyanamic-contet">13. Healthcare</h1>
            <p class="mb-3 mt-4">
                Some of the information contained on the Site is presented for the purpose of educating consumers on wellness and disease management topics and/or educating the public on emerging healthcare issues. Nothing contained in the Site is intended to be instruction for medical diagnosis or treatment. The information should not be considered complete, nor should it be relied on to suggest a course of treatment for a particular individual. It should not be used in place of the advice of your physician or other qualified healthcare provider. Information obtained from the Site is not exhaustive and does not cover all diseases, ailments, physical conditions or their treatment. Should you have any healthcare-related questions, please consult your physician or other qualified healthcare provider promptly. Always consult with your physician or other qualified healthcare provider before embarking on a new treatment, diet or fitness program. You should never disregard medical advice or delay in seeking it because of something you have read on the Site. The information contained on the Site is compiled from a variety of sources (“Information Providers”). Neither Lattice nor the Information Providers directly or indirectly practice medicine or dispense medical services as part of the Site.
            </p>

            <h1 class="heading-of-dyanamic-contet">14. Disclaimer</h1>
            <p class="mb-3 mt-4">
                EXCEPT WHERE EXPRESSLY PROVIDED OTHERWISE, THE SITE, AND ALL CONTENT PROVIDED ON OR THROUGH THE SITE, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. LATTICE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT WITH RESPECT TO THE SITE AND ALL CONTENT PROVIDED ON OR THROUGH THE SITE. LATTICE MAKES NO WARRANTY THAT: (A) THE SITE OR CONTENT WILL MEET YOUR REQUIREMENTS; (B) THE SITE WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SITE OR ANY CONTENT PROVIDED ON OR THROUGH THE SITE WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY CONTENT PURCHASED OR OBTAINED BY YOU ON OR THROUGH THE SITE WILL MEET YOUR EXPECTATIONS.
            </p>
            <p class="mb-3 mt-4">
                CONTENT PROVIDED ON OR THROUGH THE SITE SHOULD NOT BE RELIED UPON FOR PERSONAL, MEDICAL, LEGAL, TECHNICAL OR FINANCIAL DECISIONS AND YOU SHOULD CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
            </p>
            <p class="mb-3 mt-4">
                ANY CONTENT ACCESSED, DOWNLOADED OR OTHERWISE OBTAINED ON OR THROUGH THE USE OF THE SITE IS USED AT YOUR OWN DISCRETION AND RISK. LATTICE SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF CONTENT.
            </p>
            <p class="mb-3 mt-4">
                LATTICE RESERVES THE RIGHT TO MAKE CHANGES OR UPDATES TO, AND MONITOR THE USE OF, THE SITE AND CONTENT PROVIDED ON OR THROUGH THE SITE AT ANY TIME WITHOUT NOTICE.
            </p>

            <h1 class="heading-of-dyanamic-contet">15. Limitation of Liability</h1>
            <p class="mb-3 mt-4">
            IN NO EVENT SHALL LATTICE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA OR DATA USE, INCURRED BY YOU OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR TORT, ARISING FROM YOUR ACCESS TO, OR USE OF, THE SITE OR ANY CONTENT PROVIDED ON OR THROUGH THE SITE.
            </p>

            <h1 class="heading-of-dyanamic-contet">16. Exclusions and Limitations</h1>
            <p class="mb-3 mt-4">
            SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE DISCLAIMER, EXCLUSION OR LIMITATION OF CERTAIN LIABILITIES. TO THE EXTENT THAT THEY ARE HELD TO BE LEGALLY INVALID, DISCLAIMERS, EXCLUSIONS AND LIMITATIONS SET FORTH IN THESE TERMS OF USE, INCLUDING THOSE SET FORTH IN SECTIONS 14, 15 AND 16, DO NOT APPLY AND ALL OTHER TERMS SHALL REMAIN IN FULL FORCE AND EFFECT.
            </p>

            <h1 class="heading-of-dyanamic-contet">17. Privacy Policy</h1>
            <p class="mb-3 mt-4">
            Lattice is concerned about your privacy and has developed a policy to address privacy concerns. For more information, please see Lattice’s Privacy Policy. Any personal information collected on this Site may be accessed and stored globally and will be treated in accordance with Lattice’s Privacy Policy. You understand that Lattice collects, uses, processes, possesses, and otherwise stores your personal information and utilization data and may share such data with third-party service providers for the purpose of improving or providing services subject to Lattice’s Privacy Policy.
            </p>

            <h1 class="heading-of-dyanamic-contet">18. Persons Not of Age of Majority</h1>
            <p class="mb-3 mt-4">
            Persons who are not of the age of majority are not eligible to use the Site, and we ask that no information in relation to such persons be submitted to us.
            </p>

            <h1 class="heading-of-dyanamic-contet">19. DHMSM License and Distribution Restrictions</h1>
            <p class="mb-3 mt-4">
            U.S. Government license and distribution restrictions are set forth here.
            </p>

            <h1 class="heading-of-dyanamic-contet">20. Export Restrictions/Legal Compliance</h1>
            <p class="mb-3 mt-4">
            You may not access, download, use or export the Site, or the Content provided on or through the Site, in violation of U.S. export laws or regulations, or in violation of any other applicable laws or regulations. You agree to comply with all export laws, restrictions, and regulations of any United States or applicable agency or authority, and to not directly or indirectly provide or otherwise make available any services and products of Lattice in violation of any such restrictions, laws, or regulations, including, without limitation, laws, restrictions, or regulations pertaining to the development, design, manufacture, or production of nuclear, chemical, or biological weapons or missile technology. As applicable, you shall obtain and bear all expenses related to any necessary licenses, authorizations, and/or exemptions with respect to your own use of the services of Lattice outside the U.S. Neither the services or products of Lattice nor the underlying information or technology may be downloaded or otherwise provided or made available, either directly or indirectly, into any country subject to U.S. trade sanctions (Supplement No. 1 to Part 740, Export Administration Regulations, Country Group E:1), to individuals or entities controlled by such countries, or to nationals or residents of such countries other than nationals who are lawfully admitted permanent residents of countries not subject to such sanctions. By agreeing to these Terms of Use, you agree to the foregoing and represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list.
            </p>

            <h1 class="heading-of-dyanamic-contet">21. Forward-looking and Cautionary Statements</h1>
            <p class="mb-3 mt-4">
            Statements set forth in the Site may constitute forward-looking and cautionary statements within the meaning of the Private Securities Litigation Reform Act of 1995 or other applicable laws. While these forward-looking statements represent our current judgment on what the future holds, they are subject to risks and uncertainties that could cause actual results to differ materially. You are cautioned not to place undue reliance on these forward-looking statements, which reflect our opinions only as of the date such statements are set forth in the Site. Please keep in mind that we are not obligating ourselves to revise or publicly release the results of any revision to these forward-looking statements in light of new information or future events. You should also review our most recent Form 10-K and Form 10-Q for a more complete discussion of these factors and other risks, particularly under the heading “Risk Factors.” A copy of our press release and financial tables which include a GAAP to non-GAAP reconciliation can be viewed and downloaded on the Lattice Investor Relations website.
            </p>

            <h1 class="heading-of-dyanamic-contet">22. Availability of Products</h1>
            <p class="mb-3 mt-4">
            Information on the Site may reference or cross-reference Lattice products, programs, or services that might not be available in your location. Such references do not imply that Lattice plans to make such products, programs, or services available in your location. Please contact your local Lattice representative for more information.
            </p>

            <h1 class="heading-of-dyanamic-contet">23. Waiver and Severability</h1>
            <p class="mb-3 mt-4">
            The failure of Lattice to exercise or enforce any rights or provisions in these Terms of Use shall not constitute a waiver of such right or provision. If any part or provision of these Terms of Use is found to be unenforceable, such part or provision may be modified to make the Terms of Use as modified legal and enforceable. The balance of the Terms of Use shall not be affected.
            </p>

            <h1 class="heading-of-dyanamic-contet">24. Applicable Laws</h1>
            <p class="mb-3 mt-4">
            All matters relating to your access to, and use of, the Site and Content provided on or through or uploaded to the Site shall be governed by U.S. federal law or the laws of the State of California. Any legal action or proceeding relating to your access to, or use of, the Site or Content shall be instituted in a state or federal court in San Francisco or Santa Clara County, California. You and Lattice agree to submit to the jurisdiction of, and agree that venue is proper in, these courts in any such legal action or proceeding.
            </p>

            <h1 class="heading-of-dyanamic-contet">25. No Integration</h1>
            <p class="mb-3 mt-4">
            Some materials on the Site pertain to Lattice Health, Lattice Cerner, and Cerner Enviza, which are all wholly-owned subsidiaries of Lattice Corporation. Nothing on this website should be taken as indicating that any decisions regarding the integration of any EMEA Cerner and/or Enviza entities have been made where an integration has not already occurred.
            </p>

            <h1 class="heading-of-dyanamic-contet">26. Copyright/Trademark</h1>
            <p class="mb-3 mt-4">
            Copyright © 1995, 2024, Lattice and/or its affiliates.

            Lattice®, Java, MySQL, and NetSuite are registered trademarks of Lattice and/or its affiliates. Other names appearing on the Site may be trademarks of their respective owners.

            More information on the use of Lattice trademarks.

            More information on making claims of copyright infringement. Unauthorized copying, distribution, modification, public display, or public performance of copyrighted works may be an infringement of the rights of the copyright owner. You agree that you will not use the Site or any Lattice site to infringe Lattice’s Intellectual Property rights or the Intellectual Property rights of others. You may not remove, deface, or overprint any notice of copyright, trademark, logo, or other notice of ownership from any originals or copies of Lattice software, third-party software, or any products or Content you access on or through Lattice’s Sites.
            </p>

            <h1 class="heading-of-dyanamic-contet">27. Contact Information</h1>
            <p class="mb-3 mt-4">
            If you have any questions regarding these Terms of Use, please contact Lattice at trademar_us@lattice.com. If you have questions about our Communities, please see our policies and contact information here. If you have any other questions, contact information is available at the Contact Lattice page on the Site.
            </p>

            <p class="mb-3 mt-4">Last Revised: February 6, 2024</p>



         
        </div>
    `,
    Copyright: `

         <h1 class="heading-of-dyanamic-contet">Procedure for Making Claims of Copyright Infringement</h1></br>

        <p class="mb-3 mt-4">Lattice respects the intellectual property rights of others, and we ask our individuals using Lattice Web sites to do the same. If you believe that your copyrighted work has been copied and is accessible on an Lattice Web site in a way that constitutes copyright infringement, please send a Notice to our Copyright Agent providing the following information:</p>


        <ul>
            <li class="mb-2">The electronic or physical signature of the owner of the copyright or the person authorized to act on the owner’s behalf</li>

             <li class="mb-2">A description of the copyrighted work that you claim has been infringed and a description of the infringing activity</li>

             <li class="mb-2">A description of where the material that you claim is infringing is located, such as the URL where it is posted</li>

             <li class="mb-2">Your name, address, telephone number, and email address</li>

             <li class="mb-2">A statement by you that you have a good faith belief that the disputed use of the material is not authorized by the copyright owner, its agent, or the law.</li>

             <li class="mb-2">A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner’s behalf</li>

        </ul>

        <p class="mb-3 mt-4">
        Lattice’s Copyright Agent for Notice of claims of copyright infringement can be reached as follows. Note: This contact information is solely for reporting copyright infringement.
        </p>
        <p class="mb-3 mt-4">
        Lattice Corporation<br>
        500 Lattice Parkway, M/S 5op7<br>
        Redwood Shores, CA 94065<br>
        Attention: Trademark and Copyright Legal Department
        </p>
        <p class="mb-3 mt-4">
        Telephone: +1.650.506.5600<br>
        E-mail: copyright_us@lattice.com
        </p>

        <h1 class="heading-of-dyanamic-contet">Reporting Piracy of Lattice Products</h1>
        <p class="mb-3 mt-4">
        If you believe that Lattice software or documentation is being copied, sold, or distributed without licenses or permission from Lattice, please contact the Legal Department at copyright_us@lattice.com.
        </p>

        <h1 class="heading-of-dyanamic-contet">Buying Lattice Software Through Online Auctions</h1>
        <p class="mb-3 mt-4">
        Much of the Lattice software sold through online auctions is pirated or otherwise unauthorized. In addition to being sold illegally, such software may contain bugs, viruses, or other errors that could seriously damage your computer systems.
        </p>
        <p class="mb-3 mt-4">
        Lattice makes virtually all of its most popular software freely available for trial downloads in the Lattice Store or through free membership in the Lattice Technology Network.
        </p>

        <h1 class="heading-of-dyanamic-contet">Use of Lattice Screenshots</h1>
        <p class="mb-3 mt-4">
        Lattice’s software programs are copyrighted materials owned by Lattice. Screenshots constituting an image of an individual screen display from any Lattice software, application, or service are protected by copyright. (See Lattice’s Trademark Guidelines for questions about use of our company name and logos).
        </p>
        <p class="mb-3 mt-4">
        If you want to use more than ten (10) screenshots in a published piece, such as a book or white paper, you will require a formal permission letter from Lattice. Please contact our Copyright team at copyright_us@lattice.com with information about your work, the screenshots needed, and a sample chapter for our review. We may approve your request if the screenshot content does not constitute more than 25% of the content of your work.
        </p>
        <p class="mb-3 mt-4">
        If you want to use ten (10) or fewer screenshots in a published piece, you may do so provided that you agree and adhere to the following terms:
        </p>

      <ul>
        <li class="mb-2">1.
        This permission constitutes a fully revocable, nonexclusive license and is not a transfer of ownership, title, or any other right or interest in Lattice’s screenshots. Lattice may terminate permission at any time by notifying you of the termination of permission for your particular use. Lattice may revise or withdraw this general permission at any time without notice.
        </li>
        <li class="mb-2">2.
        Your use may not directly or indirectly state or imply Lattice sponsorship, affiliation, or endorsement of your product, service, or published piece.
        </li>
        <li class="mb-2">3.
        Screenshots must be reproduced in their entirety, and you may not alter screenshots in any way except to proportionally resize them. All added commentary and analysis relating to Lattice screenshots must be clearly attributable to you and not to Lattice.
        </li>
        <li class="mb-3 mt-4">4.
        This permission does not apply to any Lattice screenshots that are from beta products or other products that are subject to non-disclosure obligations or that have not yet been commercially released.
        </li>
        <li class="mb-3 mt-4">5.
        Lattice screenshots may not be incorporated into: comparative advertising; obscene, pornographic, or sexually explicit materials; or materials which are, in Lattice’s sole opinion, disparaging or defamatory of Lattice, its products, or any other person or entity.
        </li>
        <li class="mb-2">6.
        Lattice expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement in connection with your use of Lattice screenshots. Your use of Lattice screenshots is at your own discretion and risk.
        </li>
        </ul>

    `,
    Trademarks: `
        <h1 class="heading-of-dyanamic-contet">Introduction</h1>
        <p class="mb-3 mt-4">
        Lattice’s trademarks and service marks (“Lattice trademarks”) are valuable assets that Lattice needs to protect. We ask that you help us by properly using and crediting Lattice trademarks in accordance with these guidelines. For information about proper use of Lattice logos, logotypes, signatures, and design marks, please review the Third Party Usage Guidelines for Lattice Logos.
        </p>

        <h1 class="heading-of-dyanamic-contet">Permissible Use</h1>
        <p class="mb-3 mt-4">
        You may generally use Lattice trademarks to refer to the associated Lattice products or services. For instance, an authorized reseller can note in its advertisements that it is selling the Lattice application server. Similarly, a Lattice customer may issue a press release stating that it has implemented Lattice software.
        </p>

        <h1 class="heading-of-dyanamic-contet">Relationship of Products or Services</h1>
        <p class="mb-3 mt-4">
        You may indicate the relationship of your products or services to Lattice products or services by using accurate, descriptive tag lines such as “for Lattice database,” “for use with Lattice E-Business Suite applications,” and “works with Lattice software” in connection with your product or service name. Within text or body copy, such tag lines may appear in the same type as your product or service name. On product, packaging, advertising and other collateral where your product or service name is displayed apart from body copy, make sure that the tag line appears in significantly smaller type than your name. You should also distinguish the tag line from your mark by using a different font or color. However, Lattice or the tag line should never appear in the Lattice red color. This is important to avert any implication that your product or service is produced or endorsed by Lattice.
        </p>

        <h1 class="heading-of-dyanamic-contet">Titles</h1>
        <p class="mb-3 mt-4">
        Lattice permits use of its marks in single volume book titles (not magazines or periodicals) where such use is descriptive or referential. To avoid misleading the public as to Lattice sponsorship, affiliation or endorsement, the Lattice mark must not appear more prominently than the rest of the title, and do not use Lattice logos on the cover. In addition, we request that you include a disclaimer of association with Lattice on the copyright page.
        </p>

        <h1 class="heading-of-dyanamic-contet">Open Source Software</h1>
        <p class="mb-3 mt-4">
        Most open source licenses do not grant, and many exclude, a license of trademark rights. Do not assume you can use the name of a source code base in the name of your distribution developed from that code base. Without a license or permission, you may not incorporate Lattice trademarks in the name of your distribution or other products that incorporate open source elements. Truthful statements incorporating a trademark are generally allowed (for example, in the format “MyImplementation, derived from Trademarked ProductName”), but you should check the terms of the license for the original source code or any posted trademark guidelines for the project.
        </p>

        <h1 class="heading-of-dyanamic-contet">User Groups</h1>
        <p class="mb-3 mt-4">
        Lattice generally permits use of its marks in group names that include phrases such as “user group,” “special interest group,” “lobby,” etc., that clarify the relationship between Lattice and the group and do not create confusion about the source of products. This applies only to user groups that are not formally doing business as commercial entities. If you are administering a user group that includes a Lattice trademark in its name, do not claim any trademark rights in the name or attempt to register the name or your logo with a trademark office, and do not register the name as a trade name or business name, or conduct any business under the name.
        </p>

        <h1 class="heading-of-dyanamic-contet">Prohibited Use</h1>
        <p class="mb-3 mt-4">
        You may not use Lattice trademarks in a manner which could cause confusion as to Lattice sponsorship, affiliation or endorsement. Take particular care not to use Lattice marks as set out below.
        </p>

        <h1 class="heading-of-dyanamic-contet">Company, Product or Service Names</h1>
        <p class="mb-3 mt-4">
        Do not use Lattice trademarks or potentially confusing variations as all or part of your company, product or service names. If you wish to note the relationship of your products or services to Lattice products or services, please use an appropriate tag line as detailed above. For example, “XYZ for Lattice database” not “LatXYZ or XYZ Lattice.”
        </p>

        <h1 class="heading-of-dyanamic-contet">Logos</h1>
        <p class="mb-3 mt-4">
        For more information regarding use of Lattice logos, please review the Third Party Usage Guidelines for Lattice Logos.
        </p>

        <h1 class="heading-of-dyanamic-contet">Trade Dress</h1>
        <p class="mb-3 mt-4">
        You must not imitate Lattice trade dress, type style or logos. For instance, do not copy Lattice packaging for use with your product or display your product name in the distinctive logotype associated with the Lattice logo.
        </p>

        <h1 class="heading-of-dyanamic-contet">Domain Names</h1>
        <p class="mb-3 mt-4">
        Do not use Lattice trademarks or potentially confusing variations in your Internet domain name. This helps prevent Internet users from being confused as to whether you or Lattice is the source of the Web site.
        </p>

        <h1 class="heading-of-dyanamic-contet">Correct Use</h1>
        <p class="mb-3 mt-4">
        Proper use of Lattice trademarks reinforces their role as brands for our products and services and helps prevent them from becoming generic names that can be used by anyone. Examples of former trademarks that became generic terms are “aspirin,” “cellophane,” and “escalator.” By adhering to the following rules, you help protect Lattice’s investment in its trademarks.
        </p>

        <h1 class="heading-of-dyanamic-contet">Examples of Correct Use</h1>
        <ul class="examples-list">
        <li>Corporate Name: This software was developed by Lattice Corporation.</li>
        <li>Trade Name: This software was developed by Lattice.</li>
        <li>Trade Name: Lattice’s latest software developments are outstanding.</li>
        <li>Trademark: The Lattice® database leads the industry.</li>
        </ul>

         <h1 class="heading-of-dyanamic-contet">Questions</h1>
         <p class="mb-3 mt-4">If you have any questions regarding Lattice trademarks, please contact the Lattice Legal Department at +1.650.506.5600 or trademar_us@Lattice.com.</p>


    `,
    Logos: `
        <h1 class="heading-of-dyanamic-contet">Lattice Logos</h1>
        <p class="mb-3 mt-4">
        Lattice’s logos, logotypes, signatures and design marks (“Lattice logos”) are valuable assets that Lattice needs to protect. Accordingly, we ask that you follow these guidelines with respect to Lattice logos. Remember that you are generally not able to use Lattice logos unless you are specifically licensed or authorized to use them. Information regarding Logo programs under which you may be licensed to use Lattice logos and information on obtaining other authorization is set out below.
        </p>

        <h1 class="heading-of-dyanamic-contet">Logo Programs</h1>
        <p class="mb-3 mt-4">
        Qualified third parties are able to license the right to use select Lattice logos under Lattice logo programs. More information on these programs can be found by going to the web pages set out below or emailing the referenced email addresses.
        </p>

        <h1 class="heading-of-dyanamic-contet">Lattice Logo and O Tag Logo</h1>
        <p class="mb-3 mt-4">
        You must have Lattice’s written authorization to use the Lattice logo or O Tag. OPN members and members of other programs are not granted rights to use these logos.
        </p>
        <p class="mb-3 mt-4">
        If you have written authorization to use either or both of these logos, please follow these brand guidelines (PDF).
        </p>
        <p class="mb-3 mt-4">
        You will need a Lattice account to access downloads within the brand guidelines.
        </p>

        <h1 class="heading-of-dyanamic-contet">Lattice PartnerNetwork Membership and Expertise Logos</h1>
        <p class="mb-3 mt-4">
        For use by active Lattice PartnerNetwork (LPN) members only. Partners can obtain more information about LPN logos including branding guidelines on the LPN Portal, or by contacting Partner Assistance.
        </p>
        <p class="mb-3 mt-4">
        Learn more about joining Lattice PartnerNetwork.
        </p>

        <h1 class="heading-of-dyanamic-contet">Lattice Certified Professional Logo</h1>
        <p class="mb-3 mt-4">
        For use by Lattice Certified Professionals. Individuals wanting more information should go to the Lattice Certification website.
        </p>

        <h1 class="heading-of-dyanamic-contet">Lattice Academic Initiative and Workforce Development Program Logos</h1>
        <p class="mb-3 mt-4">
        For use by education partners. Information regarding these education partner initiatives can be obtained at the Lattice Academic Initiative and Workforce Development Program websites.
        </p>

        <h1 class="heading-of-dyanamic-contet">Java Logos</h1>
        <p class="mb-3 mt-4">
        For Java Logo programs, visit https://www.lattice.com/a/ocom/docs/java-licensing-logo-guidelines-1908204.pdf.
        </p>
        <p class="mb-3 mt-4">
        If you have any questions regarding Java trademarks, please contact javabrand_grp@lattice.com.
        </p>

        <h1 class="heading-of-dyanamic-contet">MySQL Logos</h1>
        <p class="mb-3 mt-4">
        For MySQL Logo programs, visit http://www.mysql.com/about/legal/.
        </p>

        <h1 class="heading-of-dyanamic-contet">Requests</h1>
        <p class="mb-3 mt-4">
        If you wish to use a Lattice logo and are a Lattice Partner, please visit the Partner Help Portal for download instructions and usage guidelines.
        </p>
        <p class="mb-3 mt-4">
        If you are not a Lattice Partner, please forward the following to trademar_us@lattice.com.
        </p>

        <ul class="requests-list">
        <li>A written summary setting forth: (a) your name and your company’s name; (b) contact information including phone number, fax number, email address and mailing address; (c) the specific Lattice logo that you wish to use; (d) background information on your company, detailing any relationship with Lattice; (e) the names of any Lattice contacts; (f) a description of your proposed use of the logo, including why you want to use the logo, where the logo would appear, the duration and geographical extent of the proposed use, how the logo would be displayed relative to your corporate name and logo, and whether the Lattice logo would be one of various logos appearing; (g) a description of Lattice’s involvement in any program or project in association with which you propose to use the Lattice logo; and (h) any previous correspondence you have had with Lattice regarding this logo request.</li>
        <li>A mock-up or sample drawing showing your proposed use.</li>
        </ul>

        <h1 class="heading-of-dyanamic-contet">Proper Use</h1>
        <p class="mb-3 mt-4">
        Third parties expressly licensed or authorized to use Lattice logos must not alter or modify them in any way. Make sure to use Lattice approved artwork and refer to the appropriate identity guidelines provided by Lattice. Use must comply with the terms and conditions of your license or Lattice’s authorization.
        </p>

        <h1 class="heading-of-dyanamic-contet">Questions</h1>
        <p class="mb-3 mt-4">
        If you have any questions regarding use of Lattice logos, please contact Lattice’s trademark and copyright group at trademar_us@lattice.com.
        </p>

    `,
    	
    LawEnforcementRequestsReport:`
        <h1 class="heading-of-dyanamic-contet">Request Types</h1>

        <h1 class="heading-of-dyanamic-contet">Administrative Subpoena</h1>
        <p class="mb-3 mt-4">
        A formal written order for information or testimony issued by a government agency without prior judicial oversight.
        </p>

        <h1 class="heading-of-dyanamic-contet">Grand Jury Subpoena</h1>
        <p class="mb-3 mt-4">
        A formal written order for information or testimony issued by a Grand Jury.
        </p>

        <h1 class="heading-of-dyanamic-contet">Preservation Request</h1>
        <p class="mb-3 mt-4">
        A written request to preserve relevant evidence, including hard copy documents and electronically stored information.
        </p>

        <h1 class="heading-of-dyanamic-contet">Search Warrant</h1>
        <p class="mb-3 mt-4">
        Order issued by local, state or federal courts that authorizes law enforcement officers to conduct a search of a person, location, or vehicle for evidence of a crime and to confiscate any evidence found during the search.
        </p>

        <h1 class="heading-of-dyanamic-contet">Take Down Notice</h1>
        <p class="mb-3 mt-4">
        Request made by a law enforcement or government agency for the removal or disablement of access to illegal, irrelevant or outdated information.
        </p>

        <h1 class="heading-of-dyanamic-contet">Miscellaneous Investigation Assistance Request</h1>
        <p class="mb-3 mt-4">
        Other request for information from a law enforcement agency in order to assist in an active investigation.
        </p>

    `
    ,
};
