import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from '../src/image/27.png';
import flag from '../src/image/flg-us.svg'
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Bootstrap JS bundle with Popper.js
import './App.css';
import { navbarContent } from './Content/eng_content';




function NavBar() {
  const [activeSection, setActiveSection] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

   // Ref to detect clicks outside dropdown
   const dropdownRef = useRef(null);
   const navbarRef = useRef(null);

  const handleNavClick = (section) => {
    if (activeSection === section && isDropdownVisible) {
      setDropdownVisible(false);
    } else {
      setActiveSection(section);
      setDropdownVisible(true);
    }
  };

  const toggleDiv = () => {
    setIsActive(!isActive);
  };


  useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && navbarRef.current) {
          // Check if the click was outside the dropdown and navbar
          if (
            !dropdownRef.current.contains(event.target) && 
            !navbarRef.current.contains(event.target)
          ) {
            setDropdownVisible(false); // Close dropdown when clicking outside
          }
        }
      };
  
      // Add event listener on mount
      document.addEventListener("mousedown", handleClickOutside);
  
      // Cleanup on unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);



  // Render the dropdown content dynamically based on the active section
  const renderDropdownContent = () => {
    if (!activeSection) return null;

    const sectionContent = navbarContent.dropdownContent[activeSection.toLowerCase()];
    if (!sectionContent) return null;

    return (
      <div className={`dropdown-content ${isDropdownVisible ? "visible" : ""}`} ref={dropdownRef}>

        <div className="dropdown-info">
          <h2>{sectionContent.title}</h2>
          {sectionContent.sections.map((section, index) => (
            <div key={index} className="product mt-3">
              <div className="listwrap">
                <ul className="oracle-list">
                  <li>
                    <strong>{section.heading}</strong>
                    <div className="grid-links">
                      <ul>
                        {section.links.map((link, idx) => (
                          <li key={idx}>
                            <a href={link.href}>{link.text}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  {/* Render subLinks if they exist */}
                  {section.subLinks && Object.keys(section.subLinks).map((key) => (
                    <li key={key}>
                      <strong>{key}</strong>
                      <div className="grid-links">
                        <ul>
                          {section.subLinks[key].map((subLink, subIdx) => (
                            <li key={subIdx}>
                              <a href={subLink.href}>{subLink.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                  {/* Add divider if not the last section */}
                  {index < sectionContent.sections.length - 1 && <hr className="dropdownborder" />}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };





  return (
    <nav ref={navbarRef} className="navbar navbar-expand-lg custom-navbar">

      <div className="container-fluid">
        {/* Logo (keeping the image as is) */}<Link to="/">
          <img src={logo} alt="latice" className="navbar-logo" /></Link>


        <div className="visible0">

          <div className="d-flex align-items-center gap-2">
            {/* Search Icon */}
            <button
              className="search-icon-button"
              onClick={() => setShowSearch(!showSearch)}
              aria-label="Toggle search bar"
              style={{
                border: "none",
                background: "none",
                padding: "0",
                cursor: "pointer",
              }}
            >
              {/* Magnifying Glass SVG */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path d="M15,15l6,6M17,10a7,7,0,1,1-7-7A7,7,0,0,1,17,10Z"></path>
              </svg>
            </button>

            {/* Search Bar */}
            {showSearch && (
              <form className="d-flex search-bar-container" role="search">
                <input
                  className="form-control me-2 search-bar"
                  type="search"
                  placeholder="Search "
                  aria-label="Search"
                />

                {/* SVG Arrow to Close Search Bar */}
                <button
                  className="search-close-button"
                  type="button"
                  onClick={() => setShowSearch(false)}
                  aria-label="Close search bar"
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0",
                    cursor: "pointer",
                  }}
                >
                  {/* Left Arrow SVG (close the search bar) */}
                  <svg width="40" height="40" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4l-4 4 4 4" stroke="#3A3631" strokeWidth="1.5" fill="none" />
                  </svg>
                </button>
              </form>
            )}


            {/* Flag Logo */}
            <img
              src={flag} /* Replace with your flag image path */
              alt="Flag"
              className="flag-logo"
            />
          </div>









          {/* Contact button */}
          <Link to="/contact" className="btn contact-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="contact-icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.894 7.894a1 1 0 0 0 1.414 0L21 8M5 19h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z"
              />
            </svg>
            <span>{navbarContent.contact}</span>
          </Link>

          {/* View Accounts Button */}
          <button
            className={`account-btn ${isActive ? "active" : ""}`}
            onClick={toggleDiv}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="account-icon"
            >
              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zM12 12c-3.314 0-6 2.686-6 6v1h12v-1c0-3.314-2.686-6-6-6z"
              />
            </svg>
            <span>{navbarContent.viewAccounts}</span>
          </button>
        </div>

        {/* Dropdown Menu */}
        {isActive && (
          <div className="account-dropdown">
            {/* Back Button */}
            <button
              className="accback-btn"
              onClick={() => {
                setIsActive(false); // Close dropdown
              }}
            >
              Back
            </button>

            {/* Dropdown Content */}
            <div className="section">
              <h3>{navbarContent.latticeCloud}</h3>
              <button
                className="acc-btn acc-btn-1"
                onClick={() => {
                  setIsActive(false); // Close dropdown
                  // Add any other logic here
                }}
              >
                {navbarContent.signInCloud}
              </button>
              <button
                className="acc-btn acc-btn-2"
                onClick={() => {
                  setIsActive(false); // Close dropdown
                  // Additional logic for signing up
                }}
              >
                {navbarContent.signUpCloud}
              </button>
            </div>
            <hr />
            <div className="section">
              <h3>Lattice Cloud</h3>
              <button
                className="acc-btn acc-btn-3"
                onClick={() => {
                  setIsActive(false); // Close dropdown
                  // Add your navigation or other logic here
                }}
              >
                <Link to="/sign-in">{navbarContent.signIn}</Link>
              </button>
              <button
                className="acc-btn acc-btn-4"
                onClick={() => {
                  setIsActive(false); // Close dropdown
                  // Logic for account creation
                }}
              > <Link to="/create-account" style={{ textDecoration: 'none', color: 'inherit' }}>
                  {navbarContent.createAccount}
                </Link>
              </button>
            </div>
          </div>
        )}
  



      {/* Toggler for mobile view */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {/* Navbar items */}
      <div className="collapse navbar-collapse navbar-items" id="navbarTogglerDemo02">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li
            className={`nav-item ${activeSection === "Products" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Products")}>
              {navbarContent.products}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Industries" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Industries")}>
              {navbarContent.industries}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Resources" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Resources")}>
              {navbarContent.resources}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Customers" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Customers")}>
              {navbarContent.customers}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Partners" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Partners")}>
              {navbarContent.partners}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Developers" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Developers")}>
              {navbarContent.developers}
            </Link>
          </li>
          <li
            className={`nav-item ${activeSection === "Company" && isDropdownVisible ? "active" : ""
              }`}
          >
            <Link className="nav-link" to="#" onClick={() => handleNavClick("Company")}>
              {navbarContent.company}
            </Link>
          </li>
        </ul>

        </div>


        {/* Dropdown Content */}
        {renderDropdownContent()}


      </div>
    </nav>
  );
}

export default NavBar;
