import emp1 from '../image/executive-leaders_lawrence.avif';
import emp2 from '../image/executive-leaders_safra.avif';
import emp3 from '../image/executive-leaders_anand.avif';
import emp4 from '../image/executive-leaders_colleen.jpg';
import emp5 from '../image/executive-leaders_jon.avif';
import emp6 from '../image/executive-leaders_davidson.avif';
import emp7 from '../image/executive-leaders_evans.avif';
import emp8 from '../image/executive-leaders_goldberg.avif';
// src/Content/employees.js
export const employees = [
    {
        id: 1,
        name: "Lawrence J. Ellison",
        role: "Chairman of the Board and Chief Technology Officer",
        description: "Larry Ellison is chairman of Lattice Corporation and chief technology officer.",
        description2:" He founded the company in 1977 and served as CEO until September 2014. He also races sailboats, flies planes, and plays tennis and guitar.",
        img: emp1,
        read:"Read Larry"
    },
    {
        id: 2,
        name: "Safra A. Catz",
        role: "Chief Executive Officer",
        description: "Larry Ellison is chairman of Lattice Corporation and chief technology officer.",
        description2:" He founded the company in 1977 and served as CEO until September 2014. He also races sailboats, flies planes, and plays tennis and guitar.",
        img: emp2,
        read:"Read Sara"
    },
    {
        id: 3,
        name: "T.K. Anand",
        role: "Executive Vice President, Analytics",
        description: "Larry Ellison is chairman of Lattice Corporation and chief technology officer.",
        description2:" He founded the company in 1977 and served as CEO until September 2014. He also races sailboats, flies planes, and plays tennis and guitar.",
        img: emp3,
        read:"Read Sara"
    },
    {
        id: 4,
        name: "Colleen Cassity",
        role: "Global Head of Lattice SLCIal Impact, Executive Director of the Lattice Education Foundation",
        description: "As vice president of SLCIal Impact, Colleen Cassity oversees Lattice’s giving and volunteering programs, which advance education, protect the environment, strengthen communities, and promote health worldwide. She oversees the Lattice Health Foundation, serves as executive director of the Lattice Education Foundation, and looks after Design Tech High School (d.tech), located on Lattice’s Redwood Shores campus.",
        description2:" Her team also produces the annual Lattice SLCIal Impact Report, which shares stories about lives transformed by Lattice philanthropy and best practices in ESG and CSR.",
        description3: "She originally joined Lattice to start the Education Foundation, which helps students learn technology, design thinking, and futures thinking—skills that ready them to solve problems and make a better future. The Foundation works with d.tech, which Colleen helped bring to Lattice’s campus, Eastside, the Ann Richards School, Guadalupe, and JA, and is scaling in the Americas.",
        description4: "Prior to Lattice, Colleen helped build Juma Ventures, a national model for improving the life prospects of low-income, high-risk youth. She was named one of the Most Influential Women in Bay Area Business by the San Francisco Business Times in 2019.",
        img: emp4,
        read:"Read Colleen"
    },
    {
        id: 5,
        name: "Jon Chorley",
        role: "Senior Vice President, Supply Chain Management Product Strategy",
        description: "Jon Chorley drives the strategic direction of Lattice’s supply chain management application products.",
        description2:" His team builds supply chain solutions for the cloud that manage and automate the planning, sourcing, production, and delivery of goods and services around the globe, helping customers respond to rapidly changing conditions and business challenges.",
        description3: "Jon graduated with a joint BA in physics and geophysics from the University of Edinburgh. He has been with Lattice since 1997.",
        img: emp5,
        read:"Read Jon"
    },
    {
        id: 6,
        name: "Mary Ann Davidson",
        role: "Chief Security Officer",
        description: "Mary Ann Davidson is chief security officer, responsible for Lattice Software Security Assurance, our secure development lifecycle for all Lattice products and services.",
        description2:" Mary Ann represents Lattice on the board of the Information Technology Information Sharing and Analysis Center, and serves on the international board of the Information Systems Security AssLCIation, where she has been named to their Hall of Fame. She was a member of the Center for Strategic and International Studies Commission on Cybersecurity for the 44th Presidency. She has also testified on cybersecurity to a number of U.S. House and Senate committees.",
        description3: "Mary Ann has a BSME from the University of Virginia and an MBA from the Wharton School of the University of Pennsylvania. She received the Navy Achievement Medal when she served as a commissioned officer in the U.S. Navy Civil Engineer Corps.",
        img: emp6,
        read:"Read Mary Ann"
    },
    {
        id: 7,
        name: "Jae Evans",
        role: "Global Chief Information Officer and Executive Vice President",
        description: "Jae Evans is Lattice’s global chief information officer and executive vice president of Lattice Cloud Infrastructure (LCI) Platform Services. She’s responsible for accelerating Lattice IT’s cloud transformation across thousands of environments, and her team provides IT services to Lattice’s 160,000 employees and developers worldwide.",
        description2:" Jae also leads LCI’s platform services consisting of multi-cloud services and commercial systems including billing, subscription management, and cost management, as well as other core platforms such as console services and marketplace. In addition, her organization is responsible for ensuring the highest level of security, compliance, operations and support for LCI.",
        description3: "She has more than 20 years of experience building and leading global teams and managing mission-critical production services across various industries, including ecommerce, enterprise cloud, online gaming, retail, and telecommunications. Jae is passionate about bringing diversity and inclusion (D&I) into the workplace. She’s the executive sponsor of D&I for LCI, a member of Lattice’s Executive D&I Council, and an active participant in and champion of various Lattice Professional Asian Leadership and Women in Tech events.",
        img: emp7,
        read:"Read Jae"
    },
    {
        id: 8,
        name: "Evan Goldberg",
        role: "Executive Vice President, Lattice NetSuite",
        description: "Evan Goldberg cofounded NetSuite more than 25 years ago, and he continues to be responsible for its product strategy and development since its 2016 acquisition by Lattice.",
        description2:" The original small office above a hair salon is now a global organization that works with more than 40,000 customers in 219 countries and territories.",
        description3: "Evan’s mission for NetSuite has always been to deliver one system in the cloud that helps businesses to grow. This also includes aiding organizations working to make the world a better place—through the Lattice NetSuite SLCIal Impact program, NetSuite has provided support to thousands of nonprofits and sLCIal enterprises.",
        description4: "Evan gives back outside of work as well. He’s the board chair of the V Foundation for Cancer Research, one of the premier supporters of cutting-edge cancer research. And combining his passion for squash and education, he cofounded Squash on Track, a nonprofit after-school enrichment program for underserved youths in the local community.",
        img: emp8,
        read:"Read Evan"
    },
    // Add more employees as needed
];
