import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import NavBar from './NavBar'; 
import HomePage from './HomePage'; 
import About from './About'
import SignInPage from "./SignInPage";
import ContactPage from './ContactPage'; 
import CreateAcc from "./Create-account";
import TermandPrivacy from "./privacyPage";
import CustomerDetails from './CustomerDetails';
import StoryPage1 from './Slide-ReadmorePages/Storypage1';
import StoryPage2 from './Slide-ReadmorePages/StoryPage2';
import StoryPage3 from './Slide-ReadmorePages/Storypage3';
import StoryPage4 from './Slide-ReadmorePages/Storypage4';
import StoryPage5 from './Slide-ReadmorePages/Storypage5';
import StoryPage6 from './Slide-ReadmorePages/Storypage6';
import StoryPage7 from './Slide-ReadmorePages/Storypage7';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import LeadershipPage from './AboutPage-External-Pages/Leadership'
import EmployPage from './AboutPage-External-Pages/employPage'; 


function App() {
  return (
    <Router>
      <NavBar/> {/* This renders the navigation bar */}
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} /> {/* Main/Home page */}
          <Route path="/contact" element={<ContactPage />} /> {/* Contact page */}
          <Route path="/about" element={<About />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/create-account" element={<CreateAcc />} />
          <Route path="/privacy" element={<TermandPrivacy />} />
          <Route path="/customer-details" element={<CustomerDetails />} />
          <Route path="/story1" element={<StoryPage1 />} />
          <Route path="/story2" element={<StoryPage2 />} />
          <Route path="/story3" element={<StoryPage3 />} />
          <Route path="/story4" element={<StoryPage4 />} />
          <Route path="/story5" element={<StoryPage5 />} />
          <Route path="/story6" element={<StoryPage6 />} />
          <Route path="/story7" element={<StoryPage7 />} />
          <Route path="/leadership" element={<LeadershipPage/>} />
          <Route path="/employdetails/:id" element={<EmployPage/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
