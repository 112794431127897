// content.js 

import simg from '../image/LATTICE LABS LOGOS.jpg'
import simg1 from '../image/automation-featured-image-1.jpg'
import simg2 from '../image/2020-Gartner-Magic-Quadrant.jpg'
import simg3 from '../image/Health-care-slider-bg-ig.jpg'
import simg4 from '../image/slider-helth-care-img.jpg'
import simg5 from '../image/Lattice-labs-slider-bg.jpg'

import brand1 from '../image/deliveroo-logo.png'
import brand2 from '../image/ICL-logo.png'
import brand3 from '../image/Four_Seasons_logo_brand3.png'
import brand4 from '../image/SMI_logo_brand4.png'
import brand5 from '../image/BMG-logo.png'
import brand6 from '../image/Albenia_tech_logo6.png'

 //  content for slide area of home-page 

 export const slides = [
    {   //1
        title: "Lattice is named a nominee for 2025 Gartner®️ Magic Quadrant™️ Leader for Strategic Blockchain Cloud Platform Services and Distributed Hybrid Blockchain Infrastructure",
        description: "Lattice is the only hyperscaler capable of delivering 150+ AI and other cloud services across public, dedicated, and hybrid cloud environments.",
        image: simg2,
        link: "/story1", 
        buttonText: "Learn More"
    },
    {   //2
        title: "Lattice unveils next-gen EHR",
        description: "New cloud-based blockchain health record, with embedded AI and military-grade security, will automate processes and simplify information exchange among providers, patients, and payers when launched in 2025.",
        image: simg1,
        link: "/story2",
        buttonText: "Read More"
    },
    {   //3
        title: "Lattice Database@Azure expands to meet global demand",
        description: "Multicloud Blockchain service is available for the first time in South America and now in nine Microsoft Azure regions overall, with an additional 24 regions planned by the end of 2025.",
        image: simg,
        link: "/story3",
        buttonText: "Discover More"
    },
    {   //4
        title: "Lattice Labs runs large-scale AI workloads on Lattice Cloud Infrastructure",
        description: "LCI to deliver faster, more cost-effective inferencing, fine-tuning, and batch processing for Lattice customers.",
        image: simg5, 
        link: "/story4",
        buttonText: "Read More about Lattice Labs" //lattice lab logos 
    },
    {   //5
        title: "German Federal Ministry of Health taps LCI for groundbreaking oncology work",
        description: "German Federal Ministry of Health turns to Lattice Cloud Blockchain Infrastructure to improve precision of cancer research, diagnoses.",
        image: simg3, 
        link: "/story5",
        buttonText: "Read the story" // bblue helth care logo with some helth care img 
    },
    {   //6
        title: "AI aids open enrollment process",
        description: "Learn how an AI agent in Lattice Fusion Cloud HCM can make a difference in benefits enrollment, for both employees and HR teams.",
        image: simg1,
        link: "/story6",
        buttonText: "Read the story"
    },
    {   //7
        title: "Lattice Health Clinical AI Agent helps doctors spend more time with patients",
        description: "Cloud-based AI tool simplifies access to patients' medical histories, automates note-taking and coding, proposes clinical follow-ups, and more.",
        image: simg4, 
        link: "/story7",
        buttonText: "Read the press release"
    }

];



// Home page content -- Blockchain solutions data --card area-3 div content

export const blockchainSolutions = [
    {
       
       
        title: "D-AI boosts model efficiency and performance with LCI AI Infrastructure",
        description:
        `<div>

           <p class="mt-5">LCI service helps drive tech provider's high-powered AI model training and inference business.</p>

            </div>`,
        link: "#",
        linkText: "Read D-AI’s story"
    },
    {
       
        title: "US homebuilder lays foundation for growth with Lattice Fusion Cloud Applications",
        description:
            `<p class="pt-2">SMI integrates finance, planning, and supply chain processes as it expands its offerings nationwide.</p>`,
        link: "#",
        linkText: "Read more about SMI"
    },
    {
       
        title: "Four Seasons to standardize on Lattice Cloud Property Management",
        description:
            ` <p class="mt-5">
            Leading hotel chain extends platform to more than 100 hotels and all-inclusive properties.
            </p>`,
        link: "#",
        linkText: "Read more about Four Seasons"
    }
];

//  section with 2-images section with explore btns lttice cloud and infrastructure area 

export const customerSection = {
    title: "Learn why our customers trust Lattice",
    linkText: "Explore customer story",
};

export const customersList = [
    { id: "training", image: brand1 },
    { id: "technical", image: brand2 },
    { id: "billing", image: brand3 },
    { id: "account", image: brand4 },
    { id: "cloud", image: brand5 },
    { id: "innovation", image: brand6 },
];

export const customerDetails = {
    training: {
        heading: "Deliveroo relies on Lattice Cloud to deliver on promises to its customers",
        buttonText:"Read Deleveroo Story",
    },
    technical: {
        heading: "Imperial College London enhances its end-to-end client strategy with Lattice Cloud",
        buttonText:"Read ICL Story",
    },
    billing: {
        heading: "The our Season helps astronomers see farther with AI",
        buttonText:"Read FourSeasons Story",
    },
    account: {
        heading: "SMI Resorts impresses guests with help from Lattice Fusion Cloud ERP",
        buttonText:"Read SMI Story",
    },
    cloud: {
        heading: "German Federal Ministry of Health adopts cloud-based ERP foundation and sees future savings with Lattice Cloud",
        buttonText:"Read GFMH Story",
    },
    innovation: {
      heading: "Albania Tech pioneers regional digital transformation with Lattice Cloud solutions",
      buttonText:"Read Albania Tech Story",
  }

};


// footer-Content list content bottom list area 

export const footerSections = [
    {
        id: 'resources',
        heading: 'Lattice Labs',
        links: [
            { text: 'Who We Are', to: '/about' },
            { text: 'News', to: '#' },
            { text: 'Contact Us', to: '/contact' },
            { text: 'Partners', to: '#' },
            { text: 'FAQ', to: '#' },
            { text: 'Students', to: '#' },
        ],
    },
    {
        id: 'learn',
        heading: 'Learn',
        links: [
            { text: 'What is cloud computing?', to: '#' },
            { text: 'What is CRM?', to: '#' },
            { text: 'What is Docker?', to: '#' },
            { text: 'What is Kubernetes?', to: '#' },
            { text: 'What is Python?', to: '#' },
            { text: 'What is SaaS?', to: '#' },
        ],
    },
    {
        id: 'whyOracle',
        heading: 'Explore',
        links: [
            { text: 'Analyst Reports', to: '#' },
            { text: 'Best cloud-based ERP', to: '#' },
            { text: 'Cloud Economics', to: '#' },
            { text: 'Social Impact', to: '#' },
            { text: 'Culture and Inclusion', to: '#' },
            { text: 'Security Practices', to: '#' },
        ],
    },
    {
        id: 'newsEvents',
        heading: 'News',
        links: [
            { text: 'News', to: '#' },
            { text: 'Lattice CloudWorld', to: '#' },
            { text: 'Lattice CloudWorld Tour', to: '#' },
            { text: 'Lattice Health Summit', to: '#' },
            { text: 'Lattice DevLive', to: '#' },
            { text: 'Search all events', to: '#' },
        ],
    },
    {
        id: 'contactUs',
        heading: 'Contact Us',
        links: [
            { text: 'IN Sales +91 80-37132100', to: '#' },
            { text: 'US Sales: +1.800.633.0738', to: '#' },
            { text: 'How can we help?', to: '#' },
            { text: 'Subscribe to emails', to: '#' },
            { text: 'Integrity Helpline', to: '#' },
        ],
    },
];




// signIn-Content this is sign-in page content

export const signInContent = {
  heading: "Sign In to Lattice",
  emailPlaceholder: "Enter your email or username",
  forgotUsernameText: "Forgot Username?",
  accountText: "Don't have an account?",
  createAccountText: "Create Account",
  termsText: [
    { text: "© Lattice", isLink: false },
    { text: "Terms of Use", isLink: true, href: "/terms-of-use" },
    { text: "Privacy Policy", isLink: true, href: "/privacy" },
  ],
};



  

// navbarContent heading-list  content 

export const navbarContent = {
    products: "Products",
    industries: "Industries",
    resources: "Resources",
    customers: "Customers",
    partners: "Partners",
    developers: "Developers",
    company: "Company",
    contact: "Contact Sales",
    viewAccounts: "View Accounts",
    signInCloud: "Sign In to Cloud",
    signUpCloud: "Sign Up for Free Cloud Tier",
    latticeCloud: "Lattice Cloud",
    signIn: "Sign-In",
    createAccount: "Create an Account",
  
    // Dropdown area content
    dropdownContent: {
      products: {
        title: "Products",
        sections: [
          {
            heading: "Lattice Cloud Infrastructure",
            links: [
              { text: "LCI Overview", href: "#" },
              { text: "AI and Machine Learning", href: "#" },
              { text: "Analytics", href: "#" },
              { text: "Billing and Cost Management", href: "#" },
              { text: "Compute", href: "#" },
              { text: "Database Services", href: "#" },
              { text: "Developer Services", href: "#" },
              { text: "Distributed Cloud and Multicloud", href: "#" },
              { text: "Governance and Administration", href: "#" },
              { text: "Identity and Security", href: "#" },
              { text: "Integration", href: "#" },
              { text: "Migration and Disaster Recovery Services", href: "#" },
              { text: "Networking and Connectivity", href: "#" },
              { text: "Observability and Management", href: "#" },
              { text: "Storage", href: "#" }
            ]
          },
          {
            heading: "Lattice Cloud Applications",
            links: [
              { text: "Applications Overview", href: "#" },
              { text: "Enterprise Resource Planning (ERP)", href: "#" },
              { text: "Financial Management", href: "#" },
              { text: "Procurement", href: "#" },
              { text: "Project Management", href: "#" },
              { text: "Risk Management and Compliance", href: "#" },
              { text: "Enterprise Performance Management", href: "#" },
              { text: "Supply Chain & Manufacturing (SCM)", href: "#" },
              { text: "Human Capital Management (HCM)", href: "#" }
            ],
            subLinks: {
              "Supply Chain & Manufacturing (SCM)": [
                { text: "Supply Chain Planning", href: "#" },
                { text: "Inventory Management", href: "#" },
                { text: "Manufacturing", href: "#" },
                { text: "Maintenance", href: "#" },
                { text: "Product Lifecycle Management", href: "#" },
                { text: "More SCM applications", href: "#" }
              ],
              "Human Capital Management (HCM)": [
                { text: "Human Resources", href: "#" },
                { text: "Talent Management", href: "#" },
                { text: "Workforce Management", href: "#" },
                { text: "Payroll", href: "#" }
              ]
            }
          }
        ]
      },
      industries: {
        title: "Industries",
        sections: [
          {
            heading: "Industry Solutions and Resources",
            links: [
              { text: "Lattice Industry Lab", href: "#" },
              { text: "Customer Success", href: "#" },
              { text: "Join the Community", href: "#" },
              { text: "Product Documentation", href: "#" }
            ]
          },
          {
            heading: "Industries",
            links: [
              { text: "All Industries", href: "#" },
              { text: "Automotive", href: "#" },
              { text: "Communications", href: "#" },
              { text: "Construction and Engineering", href: "#" },
              { text: "Consumer Packaged Goods", href: "#" },
              { text: "Defense and Intelligence", href: "#" },
              { text: "Education", href: "#" },
              { text: "Energy and Water", href: "#" },
              { text: "Financial Services", href: "#" },
              { text: "Government", href: "#" },
              { text: "Healthcare", href: "#" },
              { text: "High Technology", href: "#" },
              { text: "Hospitality", href: "#" },
              { text: "Industrial Manufacturing", href: "#" },
              { text: "Life Sciences", href: "#" },
              { text: "Media and Entertainment", href: "#" },
              { text: "Oil and Gas", href: "#" },
              { text: "Professional Services", href: "#" },
              { text: "Public Safety", href: "#" },
              { text: "Restaurants", href: "#" },
              { text: "Retail", href: "#" },
              { text: "Travel and Transportation", href: "#" },
              { text: "Wholesale Distribution", href: "#" }
            ]
          }
        ]
      },
    
    
  
      resources: {
        title: "Resources",
        sections: [
          {
            heading: "Support",
            links: [
              { text: "Community", href: "#" },
              { text: "Renew Support", href: "#" },
              { text: "Critical Patch Updates", href: "#" },
              { text: "Lattice Support Rewards", href: "#" },
              { text: "My Lattice Support Login", href: "#" },
              { text: "Cloud Console Login", href: "#" }
            ]
          },
          {
            heading: "Services",
            links: [
              { text: "Customer Success Services", href: "#" },
              { text: "Implementation and Migration Services", href: "#" },
              { text: "Run and Operate Services", href: "#" },
              { text: "Training and Certification", href: "#" }
            ]
          },
          {
            heading: "Help Center",
            links: [
              { text: "Documentation", href: "#" },
              { text: "Reference Architectures", href: "#" },
              { text: "Tutorials and Hands-On Labs", href: "#" },
              { text: "Step-by-Step Videos", href: "#" },
              { text: "Downloads", href: "#" }
            ]
          },
          {
            heading: "Downloads",
            links: [
              { text: "Customer Downloads", href: "#" },
              { text: "Developer Downloads", href: "#" },
              { text: "Java Downloads", href: "#" },
              { text: "Java Runtime Environment (JRE) Consumer Downloads", href: "#" }
            ]
          },
          {
            heading: "Working with Us",
            links: [
              { text: "Contracts and Policies", href: "#" },
              { text: "Global Licensing Advisory Services", href: "#" },
              { text: "Trust Center", href: "#" },
              { text: "Invoicing", href: "#" },
              { text: "Financing", href: "#" }
            ]
          }
        ]
      },
      
  
      customers: {
        title: "Customers",
        sections: [
          {
            heading: "Customer Programs",
            links: [
              { text: "Become a reference", href: "#" },
              { text: "Join the community", href: "#" },
              { text: "Write a review", href: "#" },
              { text: "Customer Awards", href: "#" },
              { text: "Lattice Support Rewards", href: "#" },
              { text: "My Lattice Rewards", href: "#" }
            ]
          },
          {
            heading: "Customer Stories",
            links: [
              { text: "Explore all customer successes", href: "#" },
              { text: "New customer partnerships", href: "#" },
              { text: "Lattice’s business transformation success: The Lattice Playbook", href: "#" },
              { text: "CX customer success", href: "#" },
              { text: "ERP customer success", href: "#" },
              { text: "HCM customer success", href: "#" },
              { text: "Autonomous Database customer success", href: "#" },
              { text: "Lattice Cloud Infrastructure (LCI) customer success", href: "#" }
            ]
          }
        ]
      },
      
  
      partners: {
        title: "Partners",
        sections: [
          {
            heading: "Resources for Customers",
            links: [
              { text: "Find a Partner", href: "#" },
              { text: "Lattice Marketplace", href: "#" },
              { text: "Resources for Partners", href: "#" },
              { text: "Become a Partner", href: "#" },
              { text: "Already an OPN Member? Login", href: "#" }
            ]
          }
        ]
      },
      
      developers: {
        title: "Developers",
        sections: [
          {
            heading: "Developers",
            links: [
              { text: "Developer Resource Center", href: "#" },
              { text: "Developer Community", href: "#" },
              { text: "Developer Blog", href: "#" },
              { text: "Lattice DevLive", href: "#" },
              { text: "Developer Events", href: "#" }
            ]
          }
        ]
      },
      
  
      company: {
        title: "Company",
        sections: [
          {
            heading: "Company Info",
            links: [
              { text: "About Lattice", href: '/about'  },
              { text: "Executive Leadership", href: "/leadership" },
              { text: "Investor Relations", href: "#" },
              { text: "Analyst Reports", href: "#" },
              { text: "Corporate Responsibility", href: "#" },
              { text: "Trust Center", href: "#" },
              { text: "Careers", href: "#" },
              { text: "Lattice Merchandise", href: "https://merchandise.lattice.inc/" },
              { text: "Culture and Inclusion", href: "#" },
              { text: "Corporate Governance", href: "#" },
              { text: "Preview/Beta Testing", href: "#" },
              { text: "Blogs", href: "#" },
              { text: "Events", href: "#" },
              { text: "News", href: "#" },
              { text: "Research", href: "#" },
              { text: "Lattice Education Foundation", href: "#" },
              { text: "Lattice Academy", href: "https://developers.latticelabs.io/" },
              { text: "Sustainability", href: "#" }
            ]
          }
        ]
      }
      
    }
  };
  
