import React, { useState } from 'react';
import './AboutCSS.css';
import { Link } from 'react-router-dom';
import { footerSections } from './Content/eng_content';
import { aboutContent } from './Content/about-content';


function About() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    return (
        <>
            <div className="main">
                {/* Intro Section */}
                <div className="image-aboutpage">
                    <Link className="Link-of-about" to={aboutContent.link.path}>
                        <span className="link">
                            {aboutContent.link.text}
                            <span className="arrow-aboutpage">
                                <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                    <path d={aboutContent.link.svgPath} stroke="#000000" strokeWidth="1.5" fill="none" />
                                </svg>
                            </span>
                        </span>
                    </Link>
                    <span>
                        <h1>{aboutContent.introHeading}</h1>
                    </span>
                </div>
                <div className="about-heading">
                    <p>{aboutContent.introDescription}</p>
                </div>

                {/* Scale Section */}
                <div className="card-about">
                    <div className="card1">
                        <h3>{aboutContent.scale.title}</h3>
                        <ul>
                            {aboutContent.scale.points.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="card2">
                        <h3>{aboutContent.innovationHighlights.title}</h3>
                        <ul>
                            {aboutContent.innovationHighlights.points.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* About Section */}
                <div className="about-wrapper">
                    <h1>About Lattice</h1>
                    <div className="about-grid">
                        {aboutContent.aboutSections.map((section, index) => (
                            <div className="grid-item" key={index}>
                                <h2 className="grid-item-heading">{section.heading}</h2>
                                <div className="description">{section.description}</div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Partners Section */}
                <div className="about-partner">
                    <h1>{aboutContent.partnersHeading}</h1>
                   
                        <button className='partner-btn'>Read More</button>
                </div>



                <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer-bottom">

                        <span className="footer-separator"> © 2024 Lattice</span> |

                        <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                        <span className="footer-separator">Cookie Preferences </span>

                        <span className="footer-separator">  Ad Choices </span>

                        <span className="footer-separator"> Careers </span>
                    </div>

                </div>



            </div>

        </>
    );
}

export default About;
