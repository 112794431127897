import React, { useState } from 'react';
import "./Genral-story.css"; 
import { Link } from 'react-router-dom';
import { footerSections } from '../Content/eng_content';

const StoryPage6 = () => {



    
    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    return (
        <>
            <div className="genralStory-bg-img-1">

                <div className="genralStory-transperant-navbar">

                <Link className="genralStory-Link-of-story" to="/">
                <span>
                    Lattice 
                    <span className="genralStory-arrow-StoryPage6">
                    <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4l4 4-4 4" stroke="#ffffff" strokeWidth="1.5" fill="none"/>
                    </svg>
                    </span>
                </span>
                </Link>

                </div>

            </div>

            <div className="sp6-bg-img-2">

            </div>

            <div className="genralStory-overlay-div">

               <span className="genralStory-title-story">Press Release</span>

                <h1 className="genralStory-Storyheader-text">
                Stressed out by open enrollment choices? Ask Lattice’s AI agent for help.
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
                

                <div className="sp6-icon-div">

                    
                    {/* <!-- Facebook Icon --> */}
                    <a
                        aria-label="Share on Facebook"
                        title="Share on Facebook"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="genralStory-facebook"
                        >
                        <path
                            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24H12.82v-9.294H9.692V11.53h3.128V8.785c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.794.142v3.24l-1.918.001c-1.503 0-1.795.714-1.795 1.762v2.31h3.587l-.467 3.176h-3.12V24h6.116C23.407 24 24 23.407 24 22.676V1.324C24 .593 23.407 0 22.676 0z"
                        />
                        </svg>
                       
                    </a>


                    {/* <!-- Twitter Icon --> */}
                    <a
                        aria-label="Share on Twitter"
                        title="Share on Twitter"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M22.46 6c-.77.35-1.6.58-2.46.69a4.27 4.27 0 001.88-2.36c-.83.49-1.75.83-2.72 1.02a4.27 4.27 0 00-7.3 3.9 12.1 12.1 0 01-8.77-4.45 4.27 4.27 0 001.32 5.7A4.23 4.23 0 012 9.71v.05a4.27 4.27 0 003.42 4.18c-.4.11-.82.17-1.25.17a4.25 4.25 0 01-.8-.07 4.27 4.27 0 003.99 2.96 8.56 8.56 0 01-5.3 1.83c-.34 0-.68-.02-1.01-.06a12.06 12.06 0 006.55 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.18 0-.36-.01-.54A8.8 8.8 0 0022.46 6z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- LinkedIn Icon --> */}
                    <a
                        aria-label="Share on LinkedIn"
                        title="Share on LinkedIn"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM8.339 20.5H5.337V9h3.002v11.5zm-1.502-13.002a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM20.5 20.5h-3v-5.621c0-1.341-.025-3.065-1.867-3.065-1.867 0-2.153 1.459-2.153 2.964V20.5h-3.002V9h2.883v1.562h.041c.402-.762 1.384-1.562 2.85-1.562 3.048 0 3.613 2.008 3.613 4.618V20.5z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- Email Icon --> */}
                    <a
                        aria-label="Share via Email"
                        title="Share via Email"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19.5 4h-15A2.5 2.5 0 002 6.5v11A2.5 2.5 0 004.5 20h15a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0019.5 4zm.5 2.5V6l-7.25 5.25a.75.75 0 01-.75 0L5 6v.5h15zM4.5 18a.5.5 0 01-.5-.5v-9.5L12 14.5l8-6.5v9.5a.5.5 0 01-.5.5h-15z"
                        />
                        </svg>
                      
                    </a>
                    </div>




                <p className="genralStory-special-text-para">
                Imagine having your own dedicated HR assistant, ready to answer questions about your benefits, including coverage changes and enrollment deadlines.<br></br><br></br>

                {/* With built-in AI services, leading generative AI models, and powerful AI infrastructure capabilities, Lattice continues to be acknowledged for helping customers innovate while addressing sovereign AI requirements<br></br><br></br> */}

                </p>

                <strong className="genralStory-strong-text">Nancy Estell Zoder | November 7, 2024</strong>

                <div className="genralStory-para-and-img">


                <p className="genralStory-normal-text mt-2">
                Open enrollment season is again upon us, as employees throughout the United States sift through their company’s new options and prices for healthcare and other benefits. This year, however, artificial intelligence could make the annual ritual a bit less overwhelming—for both employees and HR teams.
                </p>

                <p className="genralStory-normal-text">
                Imagine you’re Joe, an employee at a large financial services company, who’s about to welcome his first child and is worried about how his insurance coverage should change. Directly within his company’s benefits enrollment process, Joe now can ask back-and-forth questions using a capability called a benefits analyst AI agent, part of Lattice Fusion Cloud Human Capital Management (HCM). Joe asks the AI agent questions such as:
                </p>


                <div className="genralStory-side-img-of-para">

                </div>

                </div>


                <p className="genralStory-normal-text mb-5">
                We are expecting a baby in the coming year, what benefit changes should I consider?
                </p>

                <p className="genralStory-normal-text mb-5">
                What maternity-related benefits are covered for my wife under the plan (prenatal care, hospital delivery, etc.)?
                </p>

                <p className="genralStory-normal-text mb-5">
                How and by when do we need to add our newborn to the health plan, and when does coverage begin?
                </p>

                <p className="genralStory-normal-text mb-5">
                Lattice’s AI agent helps to pull data from his company’s benefits documentation and other company sources, such as existing benefit choices, benefits eligibility, or home location, as needed. The agent helps present clear, personalized, and up-to-date answers to Joe’s questions, guiding him through each step of the decision-making process. Joe comes away confident about his benefit choices.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Why AI agents matter for HR
                </h1>

                <p className="genralStory-normal-text mb-5">
                It’s not just employees like Joe who can benefit from having an AI agent assisting with questions related to open enrollment. HR teams have a lot to gain as well.
                </p>

                <p className="genralStory-normal-text mb-5">
                Open enrollment is a chance for a company to show it’s investing in employees’ well-being—something that’s more important now than ever before. Half of employees say a better understanding of their benefits would make them more loyal to their employer, the 2024 MetLife Employee Benefit Trends Study finds.
                </p>

                <p className="genralStory-normal-text mb-5">
                But engaging with each employee about benefits, focused on their unique needs, is impossible to do at scale, making open enrollment one of HR’s biggest challenges every year. Now with an AI agent acting to help as a personalized advisor, it enables employees to understand and make more informed decisions about their benefits coverage for health, dental, and vision based on their needs, background, and eligibility. Lattice’s benefits analyst AI agent is designed to help save employees time, reduce costs for the organization, and elevate the experience for everyone involved.
                </p>

                <p className="genralStory-normal-text mb-5">
                By reducing the time HR teams spend on open enrollment questions, the benefits analyst AI agent can help give them more time for strategic initiatives, such as refining benefits offerings or enhancing employee engagement programs.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Learn more about Lattice AI for Human Capital Management.
                </h1>

                <h1 className="genralStory-heading-story mb-3">
                AI agents to the rescue
                </h1>

                <p className="genralStory-normal-text">
                The new benefits analyst AI agent is one of more than 50 such AI agents that Lattice is developing to build directly into our Fusion applications. It’s an example of how we can put practical, easy-to-access tools right where and when people need information to make a decision.
                </p>

                <p className="genralStory-normal-text mb-5">
                When generative AI gained prominence two years ago, we at Lattice quickly embedded AI use cases across various workflows—such as SMART goal creation, performance reviews, and job requisitions. Lattice’s recently announced AI agents (PDF) take GenAI a step further. Embedded directly within Lattice Cloud HCM, these agents help provide personalized, real-time assistance in tasks such as hiring workflows and workforce scheduling.
                </p>

                <p className="genralStory-normal-text mb-5">
                Like the benefits agent, these AI-powered agents interact in a conversational manner, helping to guide users through tasks, answer complex questions, and provide personalized insights. By leveraging company-specific data and being embedded directly within workflows, AI agents can help improve decision-making and productivity—and will ultimately allow automation of end-to-end processes as we move into the future.
                </p>

                <p className="genralStory-normal-text mb-5">
                AI agents also retain the context from question to question throughout a conversation, allowing them to answer more complex queries. So, for example, in Joe’s benefits coverage discussion above, if Joe later mentions that he’s considering moving to Florida, the agent could adapt its coverage recommendations based on the new location, without asking Joe to repeat all the information he gave before. This makes the entire experience more personalized and efficient.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Take the next step
                </h1>

                <p className="genralStory-normal-text mb-5">
                The benefits analyst AI agent is available now.
                </p>

                <p className="genralStory-normal-text mb-5">
                If you’re an existing Lattice Fusion Cloud HCM customer, getting started is easy—contact your Lattice representative to learn everything you need to transform your benefits enrollment process.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                See other ways AI agents can help HR
               </h1>

                <p className="genralStory-normal-text mb-5">
                Learn how AI agents built into Lattice Cloud HCM help employees achieve new levels of productivity and growth.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Join the GenAI for HCM demo webinar
                </h1>

                <p className="genralStory-normal-text">
                See how generative AI and AI agents help drive HR and employee success.
                </p>

                

            </div>


            <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer-bottom">

                        <span className="footer-separator"> © 2024 Lattice</span> |

                        <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                        <span className="footer-separator">Cookie Preferences </span>

                        <span className="footer-separator">  Ad Choices </span>

                        <span className="footer-separator"> Careers </span>
                    </div>

            </div>


        </>
    );
};

export default StoryPage6;
