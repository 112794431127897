import React, { useState } from "react";
import "./CreateAcc.css";
import { Link } from "react-router-dom";
import config from "./config";


const CreateAcc = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        country: "India",
        firstName: "",
        lastName: "",
        jobTitle: "",
        workPhone: "",
        companyName: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Basic frontend validation
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
    
        try {
            const response = await fetch(`${config.BASE_API_URL}/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
    
            if (!response.ok) {
                const errorDetails = await response.json();
                console.error("Backend error:", errorDetails);
                throw new Error(errorDetails.message || "Failed to create account");
            }
    
            const data = await response.json();
            alert("Your account has been created successfully.\nThank you for choosing Lattice!");
    
            // Save auth token locally if the backend returns it
            if (data.token) {
                localStorage.setItem("authToken", data.token);
            }
    
            // Clear all form fields
            setFormData({
                email: "",
                password: "",
                confirmPassword: "",
                country: "India",
                firstName: "",
                lastName: "",
                jobTitle: "",
                workPhone: "",
                companyName: "",
                address: "",
                city: "",
                state: "",
                postalCode: "",
            });
    
            console.log("User registered:", data);
        } catch (err) {
            alert("Error creating account: " + err.message);
        }
    };
    



        return (
            <div className="create-acc-wrapper">
                <div className="create-acc-container">
                    <h2 className="create-acc-heading">Create Your Lattice Cloud Account</h2>
                    <form onSubmit={handleSubmit} className="create-acc-form">
                        {/* First and Last Name */}
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="firstName">First Name *</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lastName">Last Name *</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        {/* Email */}
                        <div className="form-group">
                            <label htmlFor="email">Email Address *</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <span className="form-helper">
                                Your email address is your username.
                            </span>
                        </div>

                        {/* Password */}
                        <div className="form-group">
                            <label htmlFor="password">Password *</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <span className="form-helper">
                                Passwords must have upper and lower case letters, at least 1 number and
                                special character, and be at least 8 characters long.
                            </span>
                        </div>

                        {/* Confirm Password */}
                        <div className="form-group">
                            <label htmlFor="confirmPassword">Retype Password *</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Country */}
                        <div className="form-group">
                            <label htmlFor="country">Country *</label>
                            <select
                                id="country"
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                            >
                                <option value="India">India</option>
                                <option value="United States">United States</option>
                                <option value="Canada">Canada</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="Australia">Australia</option>
                            </select>
                        </div>

                        {/* Job Title */}
                        <div className="form-group">
                            <label htmlFor="jobTitle">Job Title *</label>
                            <input
                                type="text"
                                id="jobTitle"
                                name="jobTitle"
                                value={formData.jobTitle}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Work Phone */}
                        <div className="form-group">
                            <label htmlFor="workPhone">Work Phone *</label>
                            <input
                                type="tel"
                                id="workPhone"
                                name="workPhone"
                                value={formData.workPhone}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Company Name */}
                        <div className="form-group">
                            <label htmlFor="companyName">Company Name *</label>
                            <input
                                type="text"
                                id="companyName"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Address */}
                        <div className="form-group">
                            <label htmlFor="address">Address *</label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* City */}
                        <div className="form-group">
                            <label htmlFor="city">City *</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* State/Province */}
                        <div className="form-group">
                            <label htmlFor="state">State/Province *</label>
                            <select
                                id="state"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                required
                            >
                                <option value="">- Select -</option>
                                <option value="Maharashtra">Maharashtra</option>
                                <option value="Karnataka">Karnataka</option>
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                            </select>
                        </div>

                        {/* ZIP/Postal Code */}
                        <div className="form-group">
                            <label htmlFor="postalCode">ZIP/Postal Code *</label>
                            <input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        {/* Agreement */}
                        <p className="create-acc-paragraph">
                            By clicking on the "Create Account" button below, you understand and agree
                            that the use of Lattice's website is subject to the Lattice.com Terms of Use.
                            Additional details regarding Lattice’s collection and use of your personal
                            information are available in the Lattice <Link to="/privacy">Privacy Policy.</Link>
                        </p>

                        <button type="submit" className="create-acc-btn">
                            Create Account
                        </button>
                    </form>
                </div>
            </div>
        );
    };

    export default CreateAcc;
