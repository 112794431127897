import React, { useState } from "react";
import './SignInPage.css'; 
import { signInContent } from './Content/eng_content'; 
import { Link, useNavigate } from "react-router-dom";
import config from './config'; // Import the config for BASE_API_URL

function SignInPage() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // For redirection

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send POST request to the backend
      const response = await fetch(`${config.BASE_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to sign in');
      }

      const data = await response.json();
      console.log('Login successful:', data);

      // Save the token to localStorage or cookies
      localStorage.setItem('authToken', data.token);

      // Show alert message and redirect after the user clicks "OK"
      alert('Sign-in successful!');
      navigate('/'); // Redirect to home page
    } catch (err) {
      console.error('Error during login:', err);
      setError(err.message);
    }
  };

  return (
    <div className="sign-in-container">
      <div className="sign-in-wrapper">
        <h1 className="sing-in-heading">{signInContent.heading}</h1>
        
        {/* First div: Email input, Next button, Forgot Username */}
        <div className="sign-in-form">
          <input 
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={signInContent.emailPlaceholder}
            className="email-input"
          />
          
          <button className="next-btn" onClick={handleSubmit}>Next</button>
          <div className="forgot-username">
            <a href="/forgot-username" className="help-link">{signInContent.forgotUsernameText}</a>
          </div>
        </div>
        
        <div className="transparent-gap"></div>

        {/* Second div: Account creation */}
        <div className="create-account">
          <p className="account-text">{signInContent.accountText}</p>
          <button className="create-account-btn"><Link to="/create-account" style={{ textDecoration: 'none', color: 'inherit' }}>{signInContent.createAccountText}</Link></button>
          <p className="account-textp">
            {signInContent.termsText.map((item, index) =>
              item.isLink ? (
                <a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: index > 0 ? "5px" : "0" }}
                >
                  {item.text}
                </a>
              ) : (
                <span key={index} style={{ marginRight: "5px" }}>
                  {item.text}
                </span>
              )
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
