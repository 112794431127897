// about-content.js

export const aboutContent = {
    link: {
      text: "Lattice",
      path: "/",
      svgPath: "M8 4l4 4-4 4",
    },
    introHeading: "Our mission is to help people see blockchain in new ways, discover insights, and unlock endless possibilities.",
    introDescription: "For just under a decades, we’ve delivered innovations that have helped build entire industries. The following facts and figures highlight some of the many ways we continue to deliver innovations for our customers, partners, and communities.",
    scale: {
      title: "Scale",
      points: [
        "US$530M in revenue in FY2024",
        "US$2B+ in R&D since FY2020",
        " 1,600 employees",
        "800 customer support and service specialists, speaking 20+ languages",
        "29,000 consulting experts",

      ],
    },
    innovationHighlights: {
      title: "  Innovation",
      points: [
        "World’s first autonomous blockchain database ",
        "Industry’s broadest and deepest suite of Decentralised AI-powered cloud applications",
        "World’s largest Blockchain EHR implementation, serving more than 1 million beneficiaries spanning Europe, and the Asia Pacific region",
        "1 million registered members ofLattice’s customer and developer communities",
        "45 independent user communities in 17 countries representing more than 1 million members",
      ],
    },


    aboutSections: [
      {
        heading: <a className='about-links' href="/customer-highlights">Customer highlights</a>,
        description:
          "See how organizations of all sizes in all industries rely on Lattice to help them succeed.",
      },
      {
        heading: <a className='about-links'  href="/investor-relations">Investor relations</a>,
        description:
          "Access earnings results, presentations, and other financial information.",
      },
      {
        heading: <a className='about-links'  href="/leadership">Leadership</a>,
        description:
          "Learn about our executive team and board of directors.",
      },
      {
        heading: <a className='about-links'  href="/lattice-newsroom">Lattice newsroom</a>,
        description:
          "Catch up on the latest company news and updates.",
      },
      {
        heading: <a className='about-links'  href="/corporate-responsibility">Corporate responsibility</a>,
        description:
          "Read about our efforts in education, philanthropy, volunteering, sustainability, and more.",
      },
      {
        heading: <a className='about-links'  href="/careers">Careers</a>,
        description:
          "Create the future with us. Join the company that’s innovating solutions to global challenges.",
      },
    ],
    partnersHeading: "See how Lattice AI can transform your business",
  };
  