import React, { useState, useEffect } from 'react';
import config from './config'; // Ensure BASE_API_URL is defined here
import './CustomerDetails.css';

const CustomerDetails = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('authToken');
        console.log('Token from LocalStorage:', token);

        if (!token) {
          setError('No token found, please login again.');
          return;
        }

        const authHeader = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
        console.log('Authorization Header:', authHeader);

        const response = await fetch(`${config.BASE_API_URL}/getalluser`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.log('Error Data:', errorData);

          if (errorData.error === 'Authentication failed: Invalid') {
            setError('Authentication failed: Invalid token. Please login again.');
            return;
          }

          throw new Error(errorData.message || 'Failed to fetch users');
        }

        const data = await response.json();
        console.log('Fetched Data:', data);

        const sortedUsers = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setUsers(sortedUsers);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) return <p>Loading users...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="customer-details-container">
      <h1>Customer Details</h1>
      <div className="user-table">
        <table>
          <thead>
            <tr>
              <th>SR No.</th> {/* Serial number column */}
              <th>Name</th>
              <th>Email</th>
              <th>Country</th>
              <th>Job Title</th>
              <th>Work Phone</th>
              <th>Company Name</th>
              <th>Address</th>
              <th>Join Date & Time</th> {/* Join Date & Time column */}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user._id}>
                <td>{index + 1}</td> {/* Serial number */}
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.country}</td>
                <td>{user.jobTitle}</td>
                <td>{user.workPhone}</td>
                <td>{user.companyName}</td>
                <td>{user.address}, {user.city}, {user.state} - {user.postalCode}</td>
                <td>{new Date(user.createdAt).toLocaleString()}</td> {/* Formatting the createdAt timestamp */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerDetails;
