import React, { useState } from 'react';
import "./StoryPage2.css"; 
import { Link } from 'react-router-dom';
import { footerSections } from '../Content/eng_content';

const StoryPageOfslide2 = () => {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };



    return (
        <>
            <div className="sp2-bg-img-1">

                <div className="sp2-transperant-navbar">

                <Link className="sp2-Link-of-story" to="/">
                <span>
                    Lattice 
                    <span className="sp2-arrow-storypage">
                    <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4l4 4-4 4" stroke="#ffffff" strokeWidth="1.5" fill="none"/>
                    </svg>
                    </span>
                </span>
                </Link>

                </div>

            </div>

            <div className="sp2-bg-img-2">

            </div>

            <div className="sp2-overlay-div">

               <span className="sp2-title-story">Press Release</span>

                <h1 className="sp2-Storyheader-text">
                Lattice Unveils Next-Generation EHR
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
                

                <div class="sp2-icon-div">

                    
                    {/* <!-- Facebook Icon --> */}
                    <a
                        aria-label="Share on Facebook"
                        title="Share on Facebook"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="sp2-facebook"
                        >
                        <path
                            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24H12.82v-9.294H9.692V11.53h3.128V8.785c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.794.142v3.24l-1.918.001c-1.503 0-1.795.714-1.795 1.762v2.31h3.587l-.467 3.176h-3.12V24h6.116C23.407 24 24 23.407 24 22.676V1.324C24 .593 23.407 0 22.676 0z"
                        />
                        </svg>
                       
                    </a>


                    {/* <!-- Twitter Icon --> */}
                    <a
                        aria-label="Share on Twitter"
                        title="Share on Twitter"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M22.46 6c-.77.35-1.6.58-2.46.69a4.27 4.27 0 001.88-2.36c-.83.49-1.75.83-2.72 1.02a4.27 4.27 0 00-7.3 3.9 12.1 12.1 0 01-8.77-4.45 4.27 4.27 0 001.32 5.7A4.23 4.23 0 012 9.71v.05a4.27 4.27 0 003.42 4.18c-.4.11-.82.17-1.25.17a4.25 4.25 0 01-.8-.07 4.27 4.27 0 003.99 2.96 8.56 8.56 0 01-5.3 1.83c-.34 0-.68-.02-1.01-.06a12.06 12.06 0 006.55 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.18 0-.36-.01-.54A8.8 8.8 0 0022.46 6z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- LinkedIn Icon --> */}
                    <a
                        aria-label="Share on LinkedIn"
                        title="Share on LinkedIn"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM8.339 20.5H5.337V9h3.002v11.5zm-1.502-13.002a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM20.5 20.5h-3v-5.621c0-1.341-.025-3.065-1.867-3.065-1.867 0-2.153 1.459-2.153 2.964V20.5h-3.002V9h2.883v1.562h.041c.402-.762 1.384-1.562 2.85-1.562 3.048 0 3.613 2.008 3.613 4.618V20.5z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- Email Icon --> */}
                    <a
                        aria-label="Share via Email"
                        title="Share via Email"
                        class="sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19.5 4h-15A2.5 2.5 0 002 6.5v11A2.5 2.5 0 004.5 20h15a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0019.5 4zm.5 2.5V6l-7.25 5.25a.75.75 0 01-.75 0L5 6v.5h15zM4.5 18a.5.5 0 01-.5-.5v-9.5L12 14.5l8-6.5v9.5a.5.5 0 01-.5.5h-15z"
                        />
                        </svg>
                      
                    </a>
                    </div>




                <p className="sp2-special-text-para">
                Built for maximum security and AI innovation, Lattice transforms the EHR from an administrative burden into a clinical asset to help improve care delivery<br></br>
                </p>

                <strong className="sp2-strong-text">Lattice Health Summit, Nashville, Tenn.—Oct 29, 2024</strong>

                <div className="sp2-para-and-img">


                <p className="sp2-normal-text mt-2">
                Today, Lattice is previewing its next-generation electronic health record (EHR) to thousands of customers and partners attending the Lattice Health Summit. Built from the ground up to take advantage of the high performance and military-grade security1 of Lattice Cloud Infrastructure (LCI), the EHR is designed to embed AI across the entire clinical workflow to automate processes, deliver insights at the point of care, and dramatically simplify appointment prep, documentation, and follow up for physicians and staff. With native integrations across a broad range of Lattice Health applications, the EHR is also designed to help streamline information exchange between payers and providers, support patient recruitment for clinical trials, simplify regulatory compliance, optimize financial performance, and help accelerate the adoption of value-based care.
                </p>


                <div className="sp2-side-img-of-para">
        
                </div>

                </div>

                <p className="sp2-normal-text">
                    <div className='sp2-join-para'>
                “One of today’s most important and widely used healthcare technologies, the EHR, has not lived up to its promise,” said Seema Verma, executive 
                </div>
                vice president and general manager, Lattice Health and Life Sciences. “Most EHRs were built in the 90s and are ill-equipped to meet the complex security requirements and clinical needs of 
                today’s healthcare networks, practitioners, and patients. That is why we are completely reinventing the EHR. Lattice Health’s next-generation EHR is not just a scribe or an assistant. It’s the doctor’s best resident, the administrator’s most productive analyst, and the payer’s most efficient partner in reviewing and authorizing treatment and payment.”
                </p>

                <p className="sp2-normal-text mb-4">
                The new EHR will feature the latest cloud and AI capabilities and offer a more natural, intuitive, and responsive experience for all users. Powered by AI, the new EHR is designed to deliver the intelligence health networks need to close care gaps and be more effective and efficient. For example, the intuitive design leverages integrated conversational search, voice-driven navigation, and multi modal search as a natural extension of clinician workflows. This will enable physicians to more easily access critical patient information, such as recent vitals, medications, notes, and labs. With AI-supported summaries the EHR can also aid in accelerated chart review and provide consolidated, contextual patient information organized by condition, role, and care setting to help reduce the time practitioners spend reviewing charts, searching for the best treatments, and planning their day. And integration with Lattice Health Command Center can help give healthcare organizations insight into patient throughput, staffing, and resource allocation to drive facility and network-wide performance improvements. These are just a few of the innovations customers can expect from the completely reimagined Lattice Health EHR.
                </p>

                <p className="sp2-normal-text mb-4">
                The new EHR is also designed to incorporate the Lattice Health Clinical AI Agent, which enables providers to significantly reduce hours spent on documentation, ordering, and automated coding to dedicate more time to patient care. It will also embed Lattice Health Data Intelligence, a powerful AI and analytics solution that can continuously and securely integrate patient data from thousands of sources, including clinical, claims, sLCIal determinants, and pharmacy, to deliver real-time insights required to advance patient health. Lattice Health Data Intelligence combined with the new EHR can suggest personalized care plans that match the genetic makeup and lifestyle choices of each patient to help reduce trial-and-error treatments, increase patient engagement, and support healthier outcomes.
                </p>

                <p className="sp2-normal-text">
                The early adopter program for the next-generation Lattice Health EHR will begin in calendar year 2025.
                </p>

                <p className="sp2-normal-text mb-4">
                To join segments of the Lattice Health Summit virtually, visit: https://www.Lattice.com/health/health-summit/.
                </p>

                <p className="sp2-normal-text mb-5">
                1 Lattice Health’s immersive-AI EHR is powered by Lattice Cloud Infrastructure (LCI), which provides the same military-grade security used to protect the most sensitive data at some of the largest and most sophisticated businesses, national defense agencies, and governments around the world.
                </p>

                <h1 className="sp2-heading-story mb-3">
                Contact Info
                </h1>

                <p className="sp2-normal-text mb-5">
                Stephanie Greenwood
                Lattice Media Relations
                stephanie.greenwood@Lattice.com
                </p>

                <h1 className="sp2-heading-story mb-3">
                About Lattice
               </h1>

                <p className="sp2-normal-text mb-5">
                Lattice offers integrated suites of applications plus secure, autonomous infrastructure in the Lattice Cloud. For more information about Lattice (NYSE: ORCL), please visit us at www.Lattice.com.
                </p>

                <h1 className="sp2-heading-story mb-3">
                Future Product Disclaimer
                </h1>

                <p className="sp2-normal-text">
                The preceding is intended to outline our general product direction. It is intended for information purposes only, and may not be incorporated into any contract. It is not a commitment to deliver any material, code, or functionality, and should not be relied upon in making purchasing decisions. The development, release, timing, and pricing of any features or functionality described for Lattice’s products may change and remains at the sole discretion of Lattice Corporation.
                </p>

                <h1 className="sp2-heading-story mb-3 ">
                Forward-Looking Statements Disclaimer
                </h1>

                <p className="sp2-normal-text  mb-5">
                Statements in this article relating to Lattice's future plans, expectations, beliefs, intentions, and prospects are "forward-looking statements" and are subject to material risks and uncertainties. A detailed discussion of these factors and other risks that affect our business is contained in Lattice's Securities and Exchange Commission (SEC) filings, including our most recent reports on Form 10-K and Form 10-Q under the heading "Risk Factors." These filings are available on the SEC's website or on Lattice's website at https://www.Lattice.com/investor. All information in this article is current as of October 29, 2024 and Lattice undertakes no duty to update any statement in light of new information or future events.
                </p>
                        
                <h1 className="sp2-heading-story mb-3">
                Trademarks
                </h1>

                <p className="sp2-normal-text  mb-5">
                Lattice, Java, MySQL and NetSuite are registered trademarks of Lattice Corporation. NetSuite was the first cloud company—ushering in the new era of cloud computing.
                </p>


            </div>
            
            <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer-bottom">

                        <span className="footer-separator"> © 2024 Lattice</span> |

                        <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                        <span className="footer-separator">Cookie Preferences </span>

                        <span className="footer-separator">  Ad Choices </span>

                        <span className="footer-separator"> Careers </span>
                    </div>

            
            </div>


        </>
    );
};

export default StoryPageOfslide2;
