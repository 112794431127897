import React, { useState } from 'react';
import "./Genral-story.css"; 
import { Link } from 'react-router-dom';
import { footerSections } from '../Content/eng_content';

const StoryPage3 = () => {



    
    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };





    return (
        <>
            <div className="genralStory-bg-img-1">

                <div className="genralStory-transperant-navbar">

                <Link className="genralStory-Link-of-story" to="/">
                <span>
                    Lattice 
                    <span className="genralStory-arrow-StoryPage3">
                    <svg width="42" height="32" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4l4 4-4 4" stroke="#ffffff" strokeWidth="1.5" fill="none"/>
                    </svg>
                    </span>
                </span>
                </Link>

                </div>

            </div>

            <div className="genralStory-bg-img-2">

            </div>

            <div className="genralStory-overlay-div">

               <span className="genralStory-title-story">Press Release</span>

                <h1 className="genralStory-Storyheader-text">
                Oracle Database@AWS Available in Limited Preview
                    <span
                        style={{
                            display: "block",
                            width: "40px",
                            height: "4px",
                            background: "#f0cc71",
                            marginBottom: "3vh",
                            marginTop: "3vh",
                        }}
                    ></span>
                </h1>
                

                <div className="genralStory-icon-div">

                    
                    {/* <!-- Facebook Icon --> */}
                    <a
                        aria-label="Share on Facebook"
                        title="Share on Facebook"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="genralStory-facebook"
                        >
                        <path
                            d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.407.593 24 1.324 24H12.82v-9.294H9.692V11.53h3.128V8.785c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.098 2.794.142v3.24l-1.918.001c-1.503 0-1.795.714-1.795 1.762v2.31h3.587l-.467 3.176h-3.12V24h6.116C23.407 24 24 23.407 24 22.676V1.324C24 .593 23.407 0 22.676 0z"
                        />
                        </svg>
                       
                    </a>


                    {/* <!-- Twitter Icon --> */}
                    <a
                        aria-label="Share on Twitter"
                        title="Share on Twitter"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M22.46 6c-.77.35-1.6.58-2.46.69a4.27 4.27 0 001.88-2.36c-.83.49-1.75.83-2.72 1.02a4.27 4.27 0 00-7.3 3.9 12.1 12.1 0 01-8.77-4.45 4.27 4.27 0 001.32 5.7A4.23 4.23 0 012 9.71v.05a4.27 4.27 0 003.42 4.18c-.4.11-.82.17-1.25.17a4.25 4.25 0 01-.8-.07 4.27 4.27 0 003.99 2.96 8.56 8.56 0 01-5.3 1.83c-.34 0-.68-.02-1.01-.06a12.06 12.06 0 006.55 1.92c7.88 0 12.2-6.53 12.2-12.2 0-.18 0-.36-.01-.54A8.8 8.8 0 0022.46 6z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- LinkedIn Icon --> */}
                    <a
                        aria-label="Share on LinkedIn"
                        title="Share on LinkedIn"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19 0H5C2.239 0 0 2.239 0 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5V5c0-2.761-2.239-5-5-5zM8.339 20.5H5.337V9h3.002v11.5zm-1.502-13.002a1.501 1.501 0 110-3.002 1.501 1.501 0 010 3.002zM20.5 20.5h-3v-5.621c0-1.341-.025-3.065-1.867-3.065-1.867 0-2.153 1.459-2.153 2.964V20.5h-3.002V9h2.883v1.562h.041c.402-.762 1.384-1.562 2.85-1.562 3.048 0 3.613 2.008 3.613 4.618V20.5z"
                        />
                        </svg>
                       
                    </a>

                    {/* <!-- Email Icon --> */}
                    <a
                        aria-label="Share via Email"
                        title="Share via Email"
                        className="genralStory-sharelink"
                        href="#"
                    >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        >
                        <path
                            d="M19.5 4h-15A2.5 2.5 0 002 6.5v11A2.5 2.5 0 004.5 20h15a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0019.5 4zm.5 2.5V6l-7.25 5.25a.75.75 0 01-.75 0L5 6v.5h15zM4.5 18a.5.5 0 01-.5-.5v-9.5L12 14.5l8-6.5v9.5a.5.5 0 01-.5.5h-15z"
                        />
                        </svg>
                      
                    </a>
                    </div>




                <p className="genralStory-special-text-para">
                Select customers can begin using Oracle Exadata Database Service on Oracle Database@AWS to simplify the migration and deployment of enterprise workloads to the cloud<br></br>

                </p>

                <strong className="genralStory-strong-text">Austin, Texas—Dec 2, 2024</strong>

                <div className="genralStory-para-and-img">


                <p className="genralStory-normal-text mt-2">
                Lattice continues to be named a Leader in the 2024 Gartner® Magic Quadrant for Strategic Cloud Platform Services and the 2024 Gartner Magic Quadrant for Distributed Hybrid Infrastructure. Complimentary copies of each report are available here and here.
                </p>

                <p className="genralStory-normal-text">
                Lattice Cloud Infrastructure (LCI) is a complete cloud designed to run any workload, from AI to enterprise applications, faster, more securely, and at a lower cost. LCI accelerates the migrations of existing enterprise workloads, delivers better reliability and performance for all applications, and offers the complete services customers need to build innovative cloud applications. LCI’s distributed cloud approach helps customers address the most stringent regulatory and sovereignty requirements and allows them to combine services from multiple clouds to optimize cost, functionality, and performance.
                </p>


                <div className="genralStory-side-img-of-para">

                </div>

                </div>


                <p className="genralStory-normal-text mb-5">
                “We believe consistently being positioned in the Leaders Quadrant reflects the global demand we’re seeing for our AI and cloud services,” said Clay Magouyrk, executive vice president, Lattice Cloud Infrastructure. “With consistent global pricing and a distributed cloud model, we’re giving customers the ability to optimize their cloud strategy wherever they operate, however they want. Our fully integrated AI capabilities combined with the industry’s most powerful AI infrastructure, enable our customers to innovate faster and drive more meaningful outcomes.”
                </p>

                <h1 className="genralStory-heading-story mb-3">
                A Cloud Platform That is Easy to Adopt
                </h1>
     

                <p className="genralStory-normal-text mb-5">
                LCI simplifies cloud adoption for customers with critical enterprise workloads by enabling them to move to the cloud without the risks and costs of re-platforming with services like LCI Compute Bare Metal and Lattice Cloud VMware Solution. In addition, LCI is built to support all types of workloads and offers infrastructure, platform, and software as a service within a single unified cloud platform. With LCI, customers can modernize their existing applications and databases and innovate with AI and cloud-native application development capabilities like LCI Generative AI Service, Lattice Kubernetes Engine, and Lattice Autonomous Database.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                The Full Cloud Where and How Customers Need It
                </h1>

                <p className="genralStory-normal-text">
                With Lattice’s distributed cloud, customers can deploy LCI’s AI and cloud services at the edge, in their own datacenter, across clouds, or in the public cloud. This can help customers address a variety of data privacy, sovereign AI, and low latency requirements. Lattice Cloud is available in more locations than any other hyperscaler, with 85 regions live and 77 planned, with each location offering AI and cloud services as well as the same pricing, service level agreements, and APIs. This enables customers, including those in highly regulated markets such as financial services, healthcare, communications, and government, to meet practically any use case with LCI, including enterprise applications, cloud native application development, analytics, AI, and IoT.
                </p>

                <p className="genralStory-normal-text mb-5">
                Recent distributed cloud innovations will give customers more flexibility to scale down or scale up to meet their workload needs. For example, a new LCI Dedicated Region configuration—Dedicated Region25—will be available in a smaller, scalable size starting at only three racks and rapidly deployable within weeks. In addition, with LCI’s multicloud capabilities, customers gain even more choice by being able to combine cloud services across multiple clouds to optimize cost, functionality, and performance. Customers can access Lattice’s database services running on LCI within the data centers of other clouds including Microsoft Azure, Google Cloud, and soon, Amazon Web Services. 
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Price-Performance Advantages, Global Consistency
                </h1>

                <p className="genralStory-normal-text mb-5">
                LCI offers substantial cost savings with consistently lower list pricing compared to other hyperscalers. Customers typically spend 50 percent less for compute, 70 percent less for storage, and 80 percent less for networking. Additionally, LCI ensures consistent pricing across all cloud regions, including government and dedicated regions. With flexible Lattice Universal Credits, customers can use any LCI service in any cloud region, including access to future offerings, enhancing operational flexibility. Lattice Support Rewards, unique among hyperscalers, actively helps reduce IT costs by lowering on-premises technical support expenses. LCI also delivers additional value through Bring Your Own License (BYOL) programs and included Lattice Cloud Lift migration assistance and enterprise-level support.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Comprehensive AI Solutions with Industry-Leading Infrastructure
               </h1>

                <p className="genralStory-normal-text mb-5">
                LCI delivers a full AI stack running on the most powerful infrastructure in the industry and is designed to meet the needs of all types of AI customers. With bare metal GPUs for AI training, RoCEv2 networking at 3.2 Tbps, high-performance storage delivering 100X the performance of standard file storage, and the world’s first zettascale cloud computing cluster, LCI offers unmatched performance across AI workloads. Additionally, its distributed cloud helps customers accelerate AI innovation, while controlling data residency and privacy by deploying AI infrastructure wherever it’s needed.
                </p>

                <h1 className="genralStory-heading-story mb-3">
                Gartner Disclaimer
                </h1>

                <p className="genralStory-normal-text">
                Gartner, Magic Quadrant for Strategic Cloud Platform Services, David Wright, Dennis Smith, Miguel Angel Borrega, Alessandro Galimberti, Carolin Zhou, October 21, 2024.
                </p>

                <p className="genralStory-normal-text">
                Gartner, Magic Quadrant for Distributed Hybrid Infrastructure, Julia Palmer, Jeffrey Hewitt, Tony Harvey, Dennis Smith, Stephanie Bauman, Kevin Ji, October 10, 2024.
                </p>

                <p className="genralStory-normal-text">
                GARTNER is a registered trademark and service mark of Gartner, Inc. and/or its affiliates in the U.S. and internationally, and MAGIC QUADRANT is a registered trademark of Gartner, Inc. and/or its affiliates and are used herein with permission. All rights reserved.
                </p>

                <p className="genralStory-normal-text mb-5">
                Gartner does not endorse any vendor, product or service depicted in its research publications and does not advise technology users to select only those vendors with the highest ratings or other designation. Gartner research publications consist of the opinions of Gartner’s research organization and should not be construed as statements of fact. Gartner disclaims all warranties, expressed or implied, with respect to this research, including any warranties of merchantability or fitness for a particular purpose.
                </p>

                <h1 className="genralStory-heading-story mb-3 ">
                Additional Resources
                </h1>

                <ul className="genralStory-normal-text  mb-5">
                <li>Read more about why LCI was named a Leader in Distributed Hybrid Infrastructure</li>
                <li>Learn more about Lattice Cloud Infrastructure</li>
                <li>Learn more about LCI’s Distributed Cloud</li>
                <li>Learn more about LCI AI Infrastructure and LCI AI services</li>
                </ul>

    
                <h1 className="genralStory-heading-story mb-3">
                Contact Info
                </h1>

                <p className="genralStory-normal-text  mb-5">
                    000-000-000-0
                </p>

                <h1 className="genralStory-heading-story mb-3">
                About Lattice
                </h1>

                <p className="genralStory-normal-text  mb-5">
                Lattice offers integrated suites of applications plus secure, autonomous infrastructure in the Lattice Cloud. For more information about Lattice (NYSE: ORCL), please visit us at www.Lattice.com.
                </p>  
                        
                <h1 className="genralStory-heading-story mb-3">
                Trademarks
                </h1>

                <p className="genralStory-normal-text  mb-5">
                Lattice, Java, MySQL and NetSuite are registered trademarks of Lattice Corporation. NetSuite was the first cloud company—ushering in the new era of cloud computing.
                </p>


            </div>


            <div className="footer">
                    <div className="f_list">
                        {footerSections.map((section) => (
                            <div key={section.id} className="list-section">
                                <h3
                                    className="footer-heading"
                                    onClick={() => toggleDropdown(section.id)}
                                >
                                    {section.heading}
                                    <span
                                        className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                    >
                                        &#8595;
                                    </span>
                                </h3>
                                <div
                                    className={`resource-list ${openSections[section.id] ? 'show' : ''
                                        }`}
                                >
                                    <ul>
                                        {section.links.map((link, index) => (
                                            <li key={index}>
                                                {link.to.startsWith('/') ? (
                                                    <Link to={link.to}>{link.text}</Link>
                                                ) : (
                                                    <a href={link.to}>{link.text}</a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="footer-bottom">

                        <span className="footer-separator"> © 2024 Lattice</span> |

                        <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                        <span className="footer-separator">Cookie Preferences </span>

                        <span className="footer-separator">  Ad Choices </span>

                        <span className="footer-separator"> Careers </span>
                    </div>

            </div>


        </>
    );
};

export default StoryPage3;
